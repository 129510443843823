import React from 'react'
import Global from '../Global/Global';
export default function Paricta3({data, facilities, id}) {
    
    const {urls, empty} = Global();
  return (

    <div className="ctaMain">
        <div className="width90 maxWidth1200 floatCenter textCenter">
            <div className="ctaInnerMain flex alignCenter justifyCenter">
                <p className="ctaText"><span>#</span> { data['ctaText'] }</p>
                <div className="donateBtnSec">
                    <a href={ data['btnLink'] } title={ data['btnText'] }>
                        <button className="donateBtn">{ data['btnText'] }</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}
