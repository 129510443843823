import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import './Login.css'
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';

export default function Login() {
    const {callApi, empty, urls} = Global();


    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(empty(formData.email)){
            window.callAlert("Email Cannot be Null", "warningAlert");
        }else if(empty(formData.password)){
            window.callAlert("Password Cannot be Null", "warningAlert");
        }else {
            const resp = await callApi(formData, "loginReact", "POST", true)
            if(resp.status !== undefined && resp.status === "OK"){
                localStorage.setItem('userid', resp.userid);
                localStorage.setItem('useremail', resp.useremail);
                window.callAlert(resp.message, "successAlert")
                setTimeout(function(){
                    window.location.href = "/user/dashboard"
                }, 2000);
            }
        }
        
    };
    useEffect(() => {
        if(!empty(localStorage.getItem('userid'))){
            window.location.href = "/user/dashboard"
        }
    }, [])

  return (
    <>
        <Menu />
        <div className='loginReact'>
            <div className='loginBox float-center'>
                <h2 className='center-text'>
                    LOGIN
                </h2>
                <form action='' method='POST' onSubmit={handleSubmit}>
                    <div className='inputBox'>
                        <label>Email<span className='text-red red-text'>*</span></label>
                        <input
                            type="email"
                            name="email"
                            className='inputF'
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="e.g info@example.com"
                            required
                        />
                    </div>
                    <br />
                    <div className='inputBox'>
                        <label>
                            Password<span className='text-red red-text'>*</span>
                            <span className='float-right'>
                                <a href='/forgot-password' title='Forgot Password?' >Forgot Password?</a>
                            </span>
                        </label>
                        <input
                            type="password"
                            name="password"
                            className='inputF'
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder="e.g ********"
                            required
                        />
                    </div>
                    <div className='inputBox'>
                        <button className='btnClas'>Login</button>
                    </div>
                    <p className='signupLogin center-text'>Don't have an account <a href='/signup' title='Signup'>Signup?</a></p>
                </form>
            </div>
        </div>
        
        <Footer />
    </>
  )
}
