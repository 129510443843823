import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';

export default function Contactsection({ data, list, id }) {
    const {callApi} = Global();
    const [social, setSocial] = useState([])

        
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      contact_no: '',
      msg: '',
      subject: '',
      required: 'name,contact_no,email,subject',
      tag: 'Contact US',
      pageurl: window.location.href,
  });
  
  

const handleSubmit = async (e) => {
    e.preventDefault();
    const resp = await callApi(formData, "formSubmitReact", "POST", true)
    if(resp.status !== undefined && resp.status === "OK"){
        window.callAlert(resp.message, "successAlert")
        window.location.href="/policy/"+resp.enqid
    }
};

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
  };

    const getFooterData = async () => {
        const resp = await callApi([], "getFooterData", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            setSocial(resp.social)
        }
    }

    useEffect(() => {
        getFooterData()
    }, [])

  return (
    
    <div className="conMain">
      <div className="width90 maxWidth1300 floatCenter">
        <div className="grid grid2 gap50 alignCenter">
          {/* Form Section */}
          <div className="formSec">
            <p className="mainHeading">{data.formHeading}</p>
            <p className="subPara">{data.formPara}</p>
            
            <form onSubmit={handleSubmit}>
              <div className="inputSec">
              <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="e.g Your Name"
                      required
                  />
              </div>
              <div className="inputSec">
              <input
                                                type="text"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                placeholder="e.g Your Email"
                                                required
                                            />
              </div>
              <div className="inputSec">
              <input
                                                type="number"
                                                name="contact_no"
                                                value={formData.contact_no}
                                                onChange={handleInputChange}
                                                placeholder="e.g Your Contact No"
                                                required
                                            />
              </div>
              <div className="inputSec">
              <input
                                                type="text"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleInputChange}
                                                placeholder="e.g Your Subject"
                                                required
                                            />
              </div>
              <div className="inputSec">
              <textarea
                                                name="msg"
                                                value={formData.msg}
                                                onChange={handleInputChange}
                                                placeholder="if Any"
                                            />
              </div>
              <div className="joinBtnSec">
                <button className="joinBtn">Submit</button>
              </div>
            </form>
          </div>

          {/* Info Section */}
          <div className="righInfoSec">
            <span className="line"></span>
            <p className="abtHead">{data.smallHead}</p>
            <p className="abtSubHead">{data.mainHeading}</p>
            <p className="abtPara">{data.subPara}</p>

            {/* Address Section */}
            <div className="border">
              {data.addressHead && (
                <div className="footerInfoCard">
                  <div className="grid footerInfoGrid gap20 alignCenter">
                    <div className="footerInfoIcon flex alignCenter justifyCenter">
                      <i className="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="footerInfoTextSec">
                      <p className="footerInfoHead">{data.addressHead}</p>
                      {[...Array(3)].map((_, i) => {
                        const index = i + 1;
                        return (
                          data[`address${index}`] && (
                            <p key={index} className="footerInfoText">
                              <a href={data[`addressLink${index}`]} title={data[`address${index}`]}>
                                {data[`address${index}`]}
                              </a>
                            </p>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

              {/* Contact Numbers */}
              {data.contactHead && (
                <div className="footerInfoCard">
                  <div className="grid footerInfoGrid gap20 alignCenter">
                    <div className="footerInfoIcon flex alignCenter justifyCenter">
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <div className="footerInfoTextSec">
                      <p className="footerInfoHead">{data.contactHead}</p>
                      {[...Array(6)].map((_, i) => {
                        const index = i + 1;
                        return (
                          data[`conatctNo${index}`] && (
                            <p key={index} className="footerInfoText">
                              <a href={`tel:${data[`conatctNo${index}`]}`} title={data[`conatctNo${index}`]}>
                                {data[`conatctNo${index}`]}
                              </a>
                            </p>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

              {/* Email Section */}
              {data.emailHead && (
                <div className="footerInfoCard">
                  <div className="grid footerInfoGrid gap20 alignCenter">
                    <div className="footerInfoIcon flex alignCenter justifyCenter">
                      <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className="footerInfoTextSec">
                      <p className="footerInfoHead">{data.emailHead}</p>
                      {[...Array(6)].map((_, i) => {
                        const index = i + 1;
                        return (
                          data[`email${index}`] && (
                            <p key={index} className="footerInfoText">
                              <a href={`mailto:${data[`email${index}`]}`} title={data[`email${index}`]}>
                                {data[`email${index}`]}
                              </a>
                            </p>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Social Links */}
            <div className="socialLinksMain flex alignCenter gap10">
              <div className="socialTextMain">
                <p className="socialText">Follow Social:</p>
              </div>
              <div className="socialIconMain flex alignCenter gap10">
                
                {
                    social.length > 0 &&
                    social.map((item, index) => {
                        return(
                            <a key={index} href={item.link} title={item.type}>
                                <div className="socialLinks flex alignCenter justifyCenter">
                                <i className={`fa-brands ${item.icon}`}></i>
                                </div>
                            </a>
                        )
                    })
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
