import React from 'react'
import Global from '../Global/Global';

export default function Marqueeslider({data, list, id}) {
    const {urls} = Global();
    const renderMarqueeText = () => {
        const texts = [];
        for (let i = 1; i <= 8; i++) {
            if (data[`marqueeSliderText${i}`]) {
                texts.push(
                    <span key={i}>{data[`marqueeSliderText${i}`]}</span>
                );
            }
        }
        return texts;
    };
  return (
        <div className="marquee-container">
            <div
                className="marquee"
                style={{
                    animationName: data.sectionType.replace(/ /g,''),
                    animationDuration: `${data.slideDuration}s`,
                }}
            >
                {renderMarqueeText()}
            </div>
        </div>
  )
}
