import React from 'react'
import Global from '../Global/Global';

export default function Keyprogramsection({data, list, id}) {
    const {urls} = Global();
    
  return (
    <>
    <div
        className="width100 whyMainSec"
        style={{ backgroundImage: 'url("' + urls.assets + data.bgImageold + '")' }}
        id="services"
      >
        <div className="width90 maxWidth1500 floatCenter">
          <div className="width50 whyUsText">
            <p className="aboutText">{data.whyUsText}</p>
            <p className="whyHeading">{data.whyUsHeading}</p>
            <p className="whySubPara">{data.whyUsSubPara}</p>
          </div>
        </div>
      </div>

      {/* Carousel Section */}
      <div className="width100 owlTop relative outerSlider">
        <div className="width90 maxWidth1500 floatCenter">
          <div
            className="owl-carousel owl-theme keyProSlider"
            id={`id${id}`}
            data-autoplaytime={data.autoplaytime}
            data-dots={data.showdots}
            data-web={data.webCount}
            data-tab={data.tabCount}
            data-mob={data.mobCount}
          >
            {Array.from({ length: 6 }, (_, i) => i + 1).map((i) => {
              const headingKey = `cardHeading${i}`;
              const iconKey = `whyUsIcon${i}old`;
              const subParaKey = `cardSubPara${i}`;

              if (data[headingKey]) {
                return (
                  <div key={i} className="item">
                    <div className="whyCardMain textCenter">
                      <div className="innerCardwhy">
                        <div className="cardIconSec">
                          <img
                            className="width100"
                            src={ urls.assets + data[iconKey]}
                            alt={data[headingKey]}
                          />
                        </div>
                        <p className="cardHeading">{data[headingKey]}</p>
                        <p className="cardSubPara">{data[subParaKey]}</p>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>

          {/* Navigation Buttons */}
          {data.shownavs === "Yes" && (
            <div className="width100 navBtnMain flex alignCenter justifyBetween absolute">
              <div className="navBtn left flex alignCenter justifyCenter">
                <i className="fa-solid fa-arrow-left"></i>
              </div>
              <div className="navBtn right flex alignCenter justifyCenter">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      </>
  )
}
