import React from 'react'
import Global from '../Global/Global';

export default function Faqsection({data, list, id}) {
    const {urls} = Global();
  return (
    <div className="faqMain">
      <div className="width90 maxWidth1300 floatCenter">
        <div className="grid grid2 gap30 alignCenter">
          <div className="leftSec">
            <span className="line"></span>
            <p className="abtHead">{data.smallHead}</p>
            <p className="abtSubHead">{data.mainHeading}</p>
            <p className="abtPara">{data.subPara}</p>

            {Array.from({ length: 20 }).map((_, index) => {
              const quesKey = `quesText${index + 1}`;
              const ansKey = `ansText${index + 1}`;
              if (data[quesKey]) {
                return (
                  <div key={index} className="faqCardSec">
                    <div className="faqCardQues flex alignCenter">
                      <p className="quesPara">
                        <span>{index + 1}.</span> {data[quesKey]}
                      </p>
                      <div className="faqIcon">
                        <div className={`plusIcon `} style={{display: (index !== 0 ? '' : 'none') }} >
                          <div className="plus">
                            <i className="fas fa-plus"></i>
                          </div>
                        </div>
                        <div className={`minusIcon`} style={{display: (index === 0 ? '' : 'none')}}>
                          <div className="minus">
                            <i className="fas fa-minus"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                      <div className="faqCardAns" style={{display: (index === 0 ? 'block' : 'none')}}>
                        <p className="ansPara">{data[ansKey]}</p>
                      </div>
                    
                  </div>
                );
              }
              return null;
            })}
          </div>

          <div className="rightSec">
            <div className="imgSec">
              <img
                className="floatRight"
                src={ urls.assets + data.mainImgold}
                alt={data.mainImgAlt}
              />
            </div>
            <div className="topImg">
              <img
                className="width100"
                src={ urls.assets + data.topImgold}
                alt={data.topImgAlt}
              />
            </div>
            <div className="botImg">
              <img
                className="width100"
                src={ urls.assets + data.botImgold}
                alt={data.botImgAlt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
