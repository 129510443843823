import React from 'react'
import Global from '../Global/Global';

export default function Paricta4({data, list, id}) {
    const {urls} = Global();
  return (
    
    <div className="ctaSec">
      <div className="handImg">
        <img
          src={ urls.assets + data.centerImgold}
          alt={data.centerImgAlt}
        />
      </div>
      <div className="width90 maxWidth1100 floatCenter">
        <div className="grid ctaGrid gap20 alignCenter">
          <div className="ctaCard">
            <span className="line"></span>
            <p className="abtHead">{data.smallHead}</p>
            <p className="abtSubHead">{data.mainHeading}</p>
          </div>
          <div className="ctaCard relative">
            <div className="arrow">
              <img
                src={ urls.assets + data.arrowImgold}
                alt={data.arrowImgAlt}
              />
            </div>
            <div className="abtMoreBtnSec floatRight">
              <a href={data.btnLink}>
                <button className="abtMoreBtn">{data.btnText}</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
