import React from 'react'
import Global from '../Global/Global';

export default function Bannersection({data, facilities, id}) {
    
    const {getBanners, urls} = Global();
    const banners = getBanners(data, 10)
    
  return (
    <div className="bannerMain relative owlTop">
        <div className="owl-carousel owl-theme bannerSlider" id={"id" + id} data-autoPlayTime={ data['autoplaytime'] } data-dots={ data['showdots'] }>

            {
                banners.length > 0 &&
                banners.map(function(item, index){
                    return(
                        <div className="item" key={"id"+id+index}>
                            <div className="bannerImg" >
                                <a href={ item['link'] } title={ item['alt'] } style={{aspectRatio:item['ratio']}} >
                                    {
                                        item.type === "Video" &&
                                        <video className="width100" loop autoplay playsinline muted >
                                            <source src={ urls.assets + (item['src']) } />
                                        </video>
                                    }
                                    {
                                        item.type === "Image" &&
                                        <img className="width100" src={ urls.assets + (item['src']) } alt={ item['alt'] } />
                                    }
                                    
                                </a>
                            </div>
                        </div>
                    )
                })
            }
        </div>

        {
            (data['shownavs'] === "Yes") &&
                <div className="width100 navBtnMain flex alignCenter justifyBetween absolute">
                    <div className="navBtn left flex alignCenter justifyCenter">
                        <i className="fa-solid fa-arrow-left"></i>
                    </div>
                    <div className="navBtn right flex alignCenter justifyCenter">
                        <i className="fa-solid fa-arrow-right"></i>
                    </div>
                </div>
        }
    </div>
  )
}
