import React, { useEffect, useState } from 'react'
export default function Mainloading() {

  return (
    <>
        <section className="cartPageLoading" id="mainPageLoading" style={{display:"none"}}>
            <div className="cartPageLoadingCon">
                <span className="loader"></span>
            </div>
        </section>
    </>
  )
}
