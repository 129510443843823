
// import Cookies from 'universal-cookie';

// const cookies = new Cookies();

export default function Global(){
    const urls = {
        "assets": "https://api.pariindia.in/",
        "api": "https://api.pariindia.in/api/",
    }

    const token = "1|3QbTkQm81ivpHoXmYvgLFmpnmlonnb8widHLmB0T77cb658a"

    async function callApi(obj, url, method, loading = false) {
        if(loading){
            window.$("#mainPageLoading").fadeIn();
        }
        const res = await fetch(urls.api + url, {
            "method": method,
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer " + token
            },
            "body": JSON.stringify(obj)
            });
        var resp = await res.json();

        if(typeof resp.message !== "undefined" && resp.status !== "OK"){
            if(typeof resp.errors !== "undefined"){
                var i = 0
                window.$.each(resp.errors, function(index, item){
                    setTimeout(function(){
                        window.callAlert(item[0], 'errorAlert')
                    }, i*1000)
                    i++
                });
            }else{
                window.callAlert(resp.message, 'errorAlert')
            }
        }else if(resp.error){
            window.callAlert(resp.error, 'errorAlert')
        }
        if(loading){
            window.$("#mainPageLoading").fadeOut();
        }
        return resp;
    }
 
    function empty(va){
        if(va !== undefined && va !== "" && va !== null && va !== 0){
            return false;
        }
        return true;
    }

    function capitalize(s) {
        return String(s[0]).toUpperCase() + String(s).slice(1);
    }
    
    

    function getBanners(data, count){
        var banners = [];
        var mobile =window.innerWidth > 900 ? false : true;  
        for(var i = 1; i <= count; i++){
            var img = (mobile) ? (data['mobile_banner'+i+'old']) : (data['pc_banner'+i+'old']);
            if(!empty(img)){
                banners.push({
                    "src" : img,
                    "type" : img.endsWith("mp4") ? 'Video' : "Image",
                    "alt" : data['banner_alt'+i],
                    "link" : data['redirect_link'+i],
                    "position" : data['banner_position' +i],
                    "ratio" : (mobile) ? data['aspectrationmob'] : data['aspectrationweb']
                });
            }
        }
        return banners
    }

    function addLessFiles(src){
        var headTag = document.getElementsByTagName("head")[0];
        const less = document.createElement('link');
        less.rel = "stylesheet/less";
        less.type = "text/css";
        less.href = src;
        headTag.appendChild(less);
        window.less.registerStylesheets();
        window.less.refresh();
        return less;
    }

    function addCss(src){
        var headTag = document.getElementsByTagName("head")[0];
        const css = document.createElement('link');
        css.rel = "stylesheet";
        css.href = src;
        headTag.appendChild(css);
        return css;
    }

    function addScript(src){
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
        return script;
    }

    async function getUser(otpvalidation = true){
        const userid = localStorage.getItem('userid');
        const useremail = localStorage.getItem('useremail');
        const resp = await callApi({id:userid, email:useremail}, "getUserReact", 'POST');
        if(resp.status === "Failed" ){
            localStorage.removeItem('userid')
            localStorage.removeItem('useremail')
            window.location.href="/login"
        }
        if(otpvalidation){
            if(resp.status === "OK" && empty(resp.user.email_verified_at) ){
                window.location.href="/user/verifyotp"
            }
        }
        return resp.user;
    }

  return {urls, callApi, token, empty, capitalize, getBanners, addLessFiles, addScript, addCss, getUser}
}
  