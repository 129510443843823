import React from 'react'
import ProductCard from './ProductCard'

export default function Paricardsection2({ data, list, id }) {
  return (
    <div className="cardSecMain">
      <div className="width90 maxWidth700 floatCenter textCenter">
        <p className="smallHead">
          <span>
            <i className="fa-regular fa-heart"></i>
          </span>
          {data.smallHead}
        </p>
        <p className="abtSubHead">{data.mainHeading}</p>
      </div>
      <div className="width90 maxWidth1300 floatCenter">
        <div className="grid grid2 gap30">
          {list.map((li, index) => (
            <ProductCard key={index} product={li} />
          ))}
        </div>
      </div>
    </div>
  )
}
