import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import Bannersection from '../Sections/Bannersection'
import Global from '../Global/Global';
import Pariabout from '../Sections/Pariabout';
import Paricta from '../Sections/Paricta';
import Paricta2 from '../Sections/Paricta2';
import Parivideosection from '../Sections/Parivideosection';
import Paricta3 from '../Sections/Paricta3';
import Paricounter from '../Sections/Paricounter';
import Paripartnerssection from '../Sections/Paripartnerssection';
import Paricardsection2 from '../Sections/Paricardsection2';
import Pariservicesection from '../Sections/Pariservicesection';
import Videosection2 from '../Sections/Videosection2';
import Parisupportsection from '../Sections/Parisupportsection';
import Cardsection1 from '../Sections/Cardsection1';
import Faqsection from '../Sections/Faqsection';
import Paricta4 from '../Sections/Paricta4';
import Blogsection from '../Sections/Blogsection';
import Testimonialsection from '../Sections/Testimonialsection';
import Loadingstatic from '../Global/Loadingstatic';
import Menu from '../Menu/Menu';
import Shortbanner from '../Sections/Shortbanner';
import Pariwhysection from '../Sections/Pariwhysection';
import Marqueeslider from '../Sections/Marqueeslider';
import Awardssection from '../Sections/Awardssection';
import Helplinesection from '../Sections/Helplinesection';
import Gallerysection from '../Sections/Gallerysection';
import Parisos from '../Sections/Parisos';
import Keyprogramsection from '../Sections/Keyprogramsection';
import Policydocumentsection from '../Sections/Policydocumentsection';
import Mediasection from '../Sections/Mediasection';
import Sidepointersection from '../Sections/Sidepointersection';
import Templatesection from '../Sections/Templatesection';
import Videogallery from '../Sections/Videogallery';
import Aboutsection2 from '../Sections/Aboutsection2';
import Successpage from '../Sections/Successpage';
import Contactsection from '../Sections/Contactsection';
import Partners3 from '../Sections/Partners3';
import Volunteerform from '../Sections/Volunteerform';

export default function Page({pageid, count}) {
    var page = pageid
    const {callApi, capitalize, addLessFiles, addScript, urls} = Global();
    const [sections, setSections] = useState(null)
    const [loading, setLoading] = useState(true)

    
    const getFooterData = async () => {
        const resp = await callApi({"pageid": page}, "getPageReact", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            setSections(resp.getSections)
            setTimeout(() => {
                window.pageFunction();
                
            }, 100);
            setLoading(false)        
        }
        
    }

    useEffect(() => {
        getFooterData();
        const less = addLessFiles( urls.assets + "assets/less/" + page + ".less");
        const script = addScript( urls.assets + 'assets/js/pages/' + page + '.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };
    }, [])
  return (
    <>
        <Menu />
        {
            loading &&
            [...Array(count)].map((item, index) => {
                return(
                    <div style={{height:"100vh"}} key={"loading" + index}>
                        <Loadingstatic />
                    </div>
                )
            })
        }
        {
            sections !== null &&
            sections.sections.length > 0 &&
            sections.sections.map((item, index) => {
                var name = capitalize(item.name.toLowerCase().replace(/ /g,''))
                return(
                    <div className={item.class} key={"key" + index}>
                        {
                            name === "Bannersection" &&
                            <Bannersection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Pariabout" &&
                            <Pariabout data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Paricta" &&
                            <Paricta data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Paricta2" &&
                            <Paricta2 data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Paricta3" &&
                            <Paricta3 data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Parivideosection" &&
                            <Parivideosection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Paricounter" &&
                            <Paricounter data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Paripartnerssection" &&
                            <Paripartnerssection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Paricardsection2" &&
                            <Paricardsection2 data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Pariservicesection" &&
                            <Pariservicesection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Videosection2" &&
                            <Videosection2 data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Parisupportsection" &&
                            <Parisupportsection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Cardsection1" &&
                            <Cardsection1 data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Faqsection" &&
                            <Faqsection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Paricta4" &&
                            <Paricta4 data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Blogsection" &&
                            <Blogsection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Testimonialsection" &&
                            <Testimonialsection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Shortbanner" &&
                            <Shortbanner data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Pariwhysection" &&
                            <Pariwhysection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Marqueeslider" &&
                            <Marqueeslider data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Awardssection" &&
                            <Awardssection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Helplinesection" &&
                            <Helplinesection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Gallerysection" &&
                            <Gallerysection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Parisos" &&
                            <Parisos data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Keyprogramsection" &&
                            <Keyprogramsection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Policydocumentsection" &&
                            <Policydocumentsection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Mediasection" &&
                            <Mediasection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Sidepointersection" &&
                            <Sidepointersection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Templatesection" &&
                            <Templatesection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Videogallery" &&
                            <Videogallery data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Aboutsection2" &&
                            <Aboutsection2 data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Successpage" &&
                            <Successpage data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Contactsection" &&
                            <Contactsection data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Partners3" &&
                            <Partners3 data={item.data} list ={item.facilities} id={item.id} />
                        }
                        {
                            name === "Volunteerform" &&
                            <Volunteerform data={item.data} list ={item.facilities} id={item.id} />
                        }
                    </div>
                )
            })
        }
        
        <Footer />
    </>
  )
}
