import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import './Login.css'
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import OtpInput from 'react-otp-input';

export default function Forgot() {
    const {callApi, empty, urls, getUser} = Global();

    const [email, setEmail] = useState('');
    const [id, setId] = useState('');

    const [otp, setOtp] = useState('');
    const [remain, setRemain] = useState(0);
    const [tab, setTab] = useState("Email")

    const [password, setPassword] = useState('');
    const [confirmed_password, setCPassword] = useState('');


    let intervalId = null; // Store interval ID

    const startTimer = () => {
      if (intervalId) return; // Prevent multiple intervals
      intervalId = setInterval(() => {
        setRemain((prev) => {
          if (prev <= 1) {
            clearInterval(intervalId); // Clear interval when time reaches 0
            intervalId = null;
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    };

    const sendOtp = async () => {
        const payload = {
            "email": email,
        }
        const otpresp = await callApi(payload, "sendOTPEmailReact", "POST", true);
        if(otpresp.status === "OK"){
            setId(otpresp.id)
            window.callAlert(otpresp.message, "successAlert")
            setTab("OTP")
            setRemain(10)
            startTimer();
        }
    }

    const verifyOTP = async () => {
        const payload = {
            "id": id,
            "email": email,
            "otp": otp,
        }
        const otpresp = await callApi(payload, "verifyOTPReact", "POST", true);
        if(otpresp.status === "OK"){
            window.callAlert(otpresp.message, "successAlert")
            setTab("Password")
            // window.location.href = "/user/dashboard"   
        }
    }

    const changePassword = async () => {
        const payload = { 
            "id": id,
            "email": email,
            "password": password,
            "confirm_password": confirmed_password,
        }
        const resp = await callApi(payload, "changePassReact", "POST", true);
        if(resp.status === "OK"){
            setTab("Email")
            window.callAlert(resp.message, "successAlert")
            setTimeout(function(){
                window.location.href = "/login"   
            }, 3000);
        }
    }

    
    useEffect(() => {
        // if(empty(localStorage.getItem('userid'))){
        //     window.location.href = "/login"
        // }
        // findUser()
        
    }, [])

  return (
    <>
        <Menu />
        
        <div className='loginReact'>
            <div className='loginBox float-center'>
                <h2 className='center-text'>
                    Forgot Password?
                </h2>
                <p className='center-text' style={{padding:"10px"}}>
                    Reset your password quickly by entering your registered email address.
                </p>
                
                    {
                        tab === "Email" &&
                        <div className='inputBox'>
                            <label>Email<span className='text-red red-text'>*</span></label>
                            <input
                                type="email"
                                name="email"
                                className='inputF'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="e.g info@example.com"
                                required
                            />
                        </div>
                    }
                    {
                        tab === "OTP" &&
                        <div className='inputBox'>
                            <br />
                            <label>Enter OTP<span className='text-red red-text'>*</span></label>
                            
                            <div className='otpBOXES'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} className='inputF' />}
                                />
                            </div>
                        </div>
                    }

                    
                    {
                        tab === "Password" &&
                        <>
                            <div className='inputBox'>
                                <label>Password<span className='text-red red-text'>*</span></label>
                                <input
                                    type="text"
                                    name="password"
                                    className='inputF'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="e.g info@PASS123"
                                    required
                                />
                            </div>
                            <br />
                            <div className='inputBox'>
                                <label>Conform Password<span className='text-red red-text'>*</span></label>
                                <input
                                    type="text"
                                    name="confirm_password"
                                    className='inputF'
                                    value={confirmed_password}
                                    onChange={(e) => setCPassword(e.target.value)}
                                    placeholder="e.g info@PASS123"
                                    required
                                />
                            </div>
                            
                            <div className='container'>
                                <div class="strongpass" >
                                    <ul className='grid grid3' style={{gap:"5px"}}>
                                        <li class="one" >
                                            <i class="fas fa-check"></i> 8 Character Length
                                        </li>
                                        <li class="two" >
                                            <i class="fas fa-check"></i> 1 Upper Case
                                        </li>
                                        <li class="three" >
                                            <i class="fas fa-check"></i> 1 Smaller case
                                        </li>
                                        <li class="four" >
                                            <i class="fas fa-check"></i> 1 Special Character
                                        </li>
                                        <li class="five" >
                                            <i class="fas fa-check"></i> 1 Digit
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </>
                    }
                    
                    {
                        tab === "OTP" &&
                        <>
                            <div className='inputBox'>
                                <button className='btnClas' onClick={() => verifyOTP()}>Verify OTP?</button>
                            </div>
                                    
                            <p className='center-text resendOTP'>
                                Didn't Received OTP? &nbsp;
                                {
                                    remain === 0 &&
                                    <span onClick={() => sendOtp()}>RESEND</span>
                                }
                                {
                                    remain > 0 &&
                                    <span style={{color:"#000000AA"}}>RESEND ({remain}s)</span>
                                }
                            </p>
                        </>
                    }
                    {
                        tab === "Email" &&
                        <div className='inputBox'>
                            <button className='btnClas' onClick={() => sendOtp()}>Send OTP?</button>
                        </div>
                    }
                    {
                        tab === "Password" &&
                        <div className='inputBox'>
                            <button className='btnClas' onClick={() => changePassword()}>Change Password?</button>
                        </div>
                    }
                
            </div>
        </div>
        <Footer />
    </>
  )
}
