import React from 'react'
import Global from '../Global/Global';

export default function Parisos({data, list, id}) {
    const {urls} = Global();
    
  return (
    
    <div className="sosMain">
      <div className="width90 maxWidth1500 floatCenter">
        <div className="grid sosGrid gap50 alignCenter">
          {/* Left Section */}
          <div className="leftSec relative">
            <div className="imgSec">
              <img className="width100" src={ urls.assets + data.imgold} alt={data.imgAlt} />
            </div>
            <div className="textSec absolute alignCenter">
              <div className="grid customGrid gap20">
                <div className="iconSec flex alignCenter justifyCenter">
                  <i className="fa-solid fa-quote-left"></i>
                </div>
                <div className="belowTextSec">
                  <p className="cardHead">{data.boxHead}</p>
                  <p className="cardPara">{data.boxSubPara}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="rightSec">
            <span className="line"></span>
            <p className="abtHead">{data.smallHead}</p>
            <p className="abtSubHead">{data.mainHeading}</p>
            <p
              className="abtPara"
              dangerouslySetInnerHTML={{ __html: data.textEdit }}
            ></p>
            <div className="pointSec">
              {Array.from({ length: 6 }, (_, i) => i + 1).map((j) => {
                const pointKey = `point${j}`;
                if (data[pointKey]) {
                  return (
                    <div
                      key={j}
                      className="grid pointGrid gap10 alignCenter marginTop10"
                    >
                      <div className="pointIcon flex alignCenter justifyCenter">
                        <i className="fa-regular fa-circle-check"></i>
                      </div>
                      <div className="pointText">
                        <p className="points">{data[pointKey]}</p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
