import React from 'react'
import Global from '../Global/Global';

export default function Partners3({data, list, id}) {
    const {urls} = Global();
  return (
    <div className="partMain">
        <div className="width90 maxWidth1300 floatCenter">
            <div className="grid grid3 gap30">
                {Array.from({ length: 20 }, (_, i) => {
                    const index = i + 1;
                    const partImgKey = `partImg${index}old`;
                    const partImgAltKey = `partImgAlt${index}`;

                    if (data[partImgKey]) {
                        return (
                            <div className="partImg" key={index}>
                                <img
                                    className="width100"
                                    src={ urls.assets + data[partImgKey]}
                                    alt={data[partImgAltKey]}
                                />
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    </div>
  )
}
