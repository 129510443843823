import React, { useEffect } from 'react'
import Global from '../Global/Global';

export default function Mobilemenu({social, menu, rightArray}) {
    const {empty, urls} = Global();
    const logoImage = urls.assets + social.menu_logoold;
    const insideLogo = urls.assets + social.mobile_menu_inside_logoold;
    const appTitle = "Welcome to Pari Foundation"
    const menuItems = menu
    const rightMenuItems = rightArray;

    useEffect(() => {
        window.feather.replace();
        window.callMobileMenu();
    }, [])
  return (
    <>

    <div className="mobileMenu">
      <div className="mobileMenuGrid">
        {/* Menu Icon */}
        <div className="menuIcon openMenu">
          <i data-feather="menu"></i>
        </div>

        {/* Mobile Logo */}
        <div className="mobileLogo">
          <a href="/" title={appTitle}>
            <img src={logoImage} alt={appTitle} />
          </a>
        </div>

        {/* Right Menu Icons */}
        <div className="rightMenuIcons">
          
            <div className="menuIcon" onClick={() => window.location.href="/login"}>
              <i data-feather="user"></i>
            </div>
            
        </div>
      </div>

    </div>

    <div className="mobileMenuSidebar" style={{ display: "none" }}>
      <div className="mobileMenuSidebarGrid">
        <div className="mobileLeftBar">
          {/* Logo */}
          <div className="mobileInsideLogo">
            <a href="/" title={appTitle}>
              <img src={insideLogo} alt={appTitle} />
            </a>
          </div>

          {/* Menu Items */}
          <ul className="newMenuList justifycenter lighnHover">
            {menuItems.map((menu, index) => (
              <li key={index} className={`smallHover ${!empty(menu.dropdown) > 0 ? "hasDropdown" : ""}`}>
                <a href={menu.link} title={menu.title}>
                  <span>{menu.title}</span>
                </a>

                {/* Dropdown */}
                {!empty(menu.dropdown) && (
                  <>
                    <div className="dropDownArrow">
                      <i className="fas fa-caret-down"></i>
                    </div>
                    <div className="newSmallDropDown">
                      <ul className="newMenuList newDowpDown">
                        {menu.dropdown.map((item, idx) => (
                          <li key={idx}>
                            <a href={item.link} title={item.title}>
                              <span>{item.title}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
                
              </li>
            ))}
            {rightMenuItems.map((menu, index) => (
              <li key={index} className='smallHover activeMenu'>
                <a href={menu.link} title={menu.title}>
                    {
                        !empty(menu.imgicon) &&
                        <div className="newMenuIcon"><img src={ urls.assets + menu.imgicon} alt={menu.title} /></div>
                    }
                    <span>{menu.title}</span>
                </a>
                </li>
            ))}
          </ul>
        </div>
        <div className="mobileRightBar"></div>
      </div>
    </div>
    </>
  )
}
