import React, { useState } from 'react'
import Global from '../Global/Global';

export default function Aboutsection2({data, list, id}) {
    const {urls} = Global();

  return (
    <>
    <div className="aboutMain">
      {/* Background Section */}
      <div className="aboutBg"></div>
      
      <div className="width90 maxWidth1300 floatCenter">
        <div className="grid grid2 gap50">
          
          {/* Left Image Section */}
          <div className="leftImgSec">
            <div className="imgSec">
              <img
                className="width80"
                src={ urls.assets + data.imgold}
                alt={data.imgAlt}
              />
              <div className="borderSec"></div>
            </div>
            
            {/* Play Video Section */}
            <div className="play_video">
              <p className="go-down-btn">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="100px"
                  height="100px"
                  viewBox="0 0 100 100"
                  enableBackground="new 0 0 100 100"
                  xmlSpace="preserve"
                >
                  <path
                    id="textPath"
                    fill="none"
                    d="M89.322,50.197c0,22.09-17.91,40-40,40c-22.089,0-40-17.91-40-40 c0-22.089,17.911-40,40-40C71.412,10.197,89.322,28.108,89.322,50.197z"
                  ></path>
                  <text className="text" style={{ color: "#1B57BC" }}>
                    <textPath xlinkHref="#textPath">
                      <tspan
                        x="10"
                        style={{ fill: "#c3002f", textTransform: "uppercase" }}
                      >
                        {data.leftAniText}
                      </tspan>
                    </textPath>
                  </text>
                </svg>
              </p>
              <a href={data.link}>
                <div className="play_btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-play"
                  >
                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                  </svg>
                </div>
              </a>
            </div>
          </div>
          
          {/* Right Section */}
          <div className="rightSec">
            <span className="line"></span>
            <p className="abtHead">{data.smallHead}</p>
            <p className="abtSubHead">{data.mainHeading}</p>
            <p className="abtPara" dangerouslySetInnerHTML={{ __html: data.textEdit }}></p>
            
            {/* Points Section */}
            <div className="pointSec">
              {Array.from({ length: 6 }, (_, index) => {
                const pointKey = `point${index + 1}`;
                if (data[pointKey]) {
                  return (
                    <div className="grid pointGrid gap10 alignCenter marginTop10" key={index}>
                      <div className="pointIcon flex alignCenter justifyCenter">
                        <i className="fa-regular fa-circle-check"></i>
                      </div>
                      <div className="pointText">
                        <p className="points">{data[pointKey]}</p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
