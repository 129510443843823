import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import Paricardsection2 from '../Sections/Paricardsection2';

export default function Ongoing() {
    const {callApi, addLessFiles, addScript, urls, empty} = Global();
    const [loading, setLoading] = useState([])
    
    const [cpage, setCPage] = useState(1)
    
    const changePage = (page) => {
        setCPage(page)
        getProduct(page)
    }

    const [products, setProducts] = useState(null)
    const getProduct = async (page) => {
        const resp = await callApi({"producttype": "Ongoing"}, "getProductsFilterReact?page="+page, "POST", true)
        if(resp.status !== undefined && resp.status === "OK"){
            setProducts(resp.products)
        }
        setLoading(false)
    }
    useEffect(() => {
        getProduct(1)
        const less = addLessFiles( urls.assets + "assets/less/homepage.less");
        const script = addScript( urls.assets + 'assets/js/pages/homepage.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };
        
    }, [])
  return (
    <>
    <Menu />
        {
            !loading &&
            <div className='p_1_s174'>
                <Paricardsection2 data={{smallHead: "Ongoing Projects", mainHeading: "Current and Ongoing Donation Projects"}} list={products.data} id={"id"}  />
                <div className="blogsV2">
                    <div className="maxWidth1500 float-center center-text navigationArea">
                        {
                        [...Array(products.last_page)].map((item, index) => {
                            return(
                                <span href={"?page=" + (index + 1)} className={"navigations " + ((index + 1) == cpage ? 'activeNav' : '') } onClick={() => changePage((index + 1))}>
                                    {index + 1}
                                </span>
                            )
                        })
                        }
                    </div>
                </div>
                <br />
            </div>
        }
        
    <Footer />
    </>
  )
}
