import React from 'react'
import './Loading.css';

export default function Loadingstatic() {
  return (
    <div>
        <div className="newLoading" id="lodingdiv" >
            <div className="fullCon">
                <span className="loader"></span>
            </div>
        </div>
    </div>
  )
}
