import React from 'react'
import Global from '../Global/Global'

export default function ProductCard({product}) {
    const {urls, empty} = Global();
  return (
    
    <div className="cardMain">
      <div className="grid cardGrid gap30 alignCenter">
        {/* Image Section */}
        <div className="cardImgSec relative">
          <img
            className="width100"
            src={ urls.assets + product.image}
            alt={product.name}
          /> 
          {
            !empty(product.goal) &&
            <p className="percent">{product.raisedper}%</p>
          }     
        </div>

        {/* Text Section */}
        <div className="cardTextSec">
          <p className="cardHead">{product.name}</p>
          <p className="cardPara">
            {product.card_description} 
          </p>

          {/* Progress Bar */}
          
          {
            !empty(product.goal) &&
            <div className="progressBar">
              <div className="outerDiv">
                <div className="innerDiv" style={{width: product.raisedper + '%'}}></div>
              </div>
            </div>
          }

          {/* Donation Info */}
          {
            !empty(product.goal) &&
            <div className="cardInfoInner">
              <div className="grid grid2 gap10">
                <div className="innerSec">
                  <p className="greyPara">Raised</p>
                  <p className="donatePara">₹{product.raised}</p>
                </div>
                <div className="innerSec">
                  <p className="greyPara">Goal</p>
                  <p className="donatePara">₹{product.goal}</p>
                </div>
              </div>
            </div>
          }

          {/* Join Button */}
          <div className="joinBtnSec">
            <a href={product.url} title="Donate Now">
              <button className="joinBtn">Donate Now</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
