import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import './Login.css'
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';

export default function Signup() {
    const {callApi, urls, empty} = Global();

    const [showPassword, setShowPassword] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        password: '',
        password_confirmation: '',
        terms: '',
    });
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(empty(formData.name)){
            window.callAlert("Name Cannot be Null", "warningAlert");
        }else if(empty(formData.email)){
            window.callAlert("Email Cannot be Null", "warningAlert");
        }else if(empty(formData.contact)){
            window.callAlert("Contact Cannot be Null", "warningAlert");
        }else if(empty(formData.password)){
            window.callAlert("Password Cannot be Null", "warningAlert");
        }else if(empty(formData.password_confirmation)){
            window.callAlert("Confirm Password Cannot be Null", "warningAlert");
        }else if(formData.password != formData.password_confirmation){
            window.callAlert("Confirm Password Doesn't Matched", "warningAlert");
        }else{
            const resp = await callApi(formData, "signupReact", "POST", true)
            if(resp.status !== undefined && resp.status === "OK"){
                localStorage.setItem('userid', resp.userid);
                localStorage.setItem('useremail', resp.useremail);
                window.callAlert("Signed up Successfully. Redirecting please Wait...", "successAlert")
                setTimeout(function(){
                    window.location.href = "/user/dashboard"
                });
            }
        }
        
    };
    
    useEffect(() => {
        if(!empty(localStorage.getItem('userid'))){
            window.location.href = "/user/dashboard"
        }
    }, [])

  return (
    <>
        <Menu />
        <div className='loginReact'>
            <div className='loginBox float-center'>
                <h2 className='center-text'>
                    SIGNUP
                </h2>
                <form action='' method='POST' onSubmit={handleSubmit}>
                    <div className='inputBox'>
                        <label>Name<span className='text-red red-text'>*</span></label>
                        <input
                            type="text"
                            name="name"
                            className='inputF'
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="e.g John Doe"
                            required
                        />
                    </div>
                    <br />
                    <div className='inputBox'>
                        <label>Email<span className='text-red red-text'>*</span></label>
                        <input
                            type="email"
                            name="email"
                            className='inputF'
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="e.g info@example.com"
                            required
                        />
                    </div>
                    <br />
                    <div className='inputBox'>
                        <label>Contact<span className='text-red red-text'>*</span></label>
                        <input
                            type="number"
                            name="contact"
                            className='inputF'
                            value={formData.contact}
                            onChange={handleInputChange}
                            placeholder="e.g 9711xxxxxx"
                            required
                        />
                    </div>
                    <br />
                    <div className='inputBox'>
                        <label>
                            Password<span className='text-red red-text'>*</span>
                            <span className='float-right'>
                                <span style={{cursor:"pointer", fontSize:"14px"}} onClick={() => setShowPassword(showPassword ? false : true)} >
                                    { showPassword ? 'Hide Password?' : 'Show Password?' }
                                </span>
                            </span>
                        </label>
                        <input
                            type= {showPassword ? 'text' : 'password'}
                            name="password"
                            className='inputF'
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder="e.g ********"
                            required
                        />
                        <div className='container'>
                            <div class="strongpass" >
                                <ul className='grid grid3' style={{gap:"5px"}}>
                                    <li class="one" >
                                        <i class="fas fa-check"></i> 8 Character Length
                                    </li>
                                    <li class="two" >
                                        <i class="fas fa-check"></i> 1 Upper Case
                                    </li>
                                    <li class="three" >
                                        <i class="fas fa-check"></i> 1 Smaller case
                                    </li>
                                    <li class="four" >
                                        <i class="fas fa-check"></i> 1 Special Character
                                    </li>
                                    <li class="five" >
                                        <i class="fas fa-check"></i> 1 Digit
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='inputBox'>
                        <label>
                            Confirm Password<span className='text-red red-text'>*</span>
                        </label>
                        <input
                            type= {showPassword ? 'text' : 'password'}
                            name="password_confirmation"
                            className='inputF'
                            value={formData.password_confirmation}
                            onChange={handleInputChange}
                            placeholder="e.g ********"
                            required
                        />

                    </div>
                    <br />
                    <div className='inputBox' style={{display:"grid", gridTemplateColumns:"20px auto", gap:10, alignItems:"center"}}>
                        <input type='checkbox' name='terms' value={formData.terms} onChange={handleInputChange} required />
                        <span>I agree Terms & Conditions</span>
                    </div>
                    <div className='inputBox'>
                        <button className='btnClas'>SIGNUP</button>
                    </div>
                    <p className='signupLogin center-text'>Already Have an account? <a href='/login' title='Signup'>Login</a></p>
                </form>
            </div>
        </div>
        
        <Footer />
    </>
  )
}
