import React from 'react'
import Global from '../Global/Global';

export default function Paricounter({data, facilities, id}) {
    const {urls, empty} = Global();
    return (
        <div className="counterMain">
            <div className="width90 maxWidth1300 floatCenter">
                <div className="counterInner">
                    <div className="grid grid4 gap20">
                        {
                            [...Array(4)].map(function(item, index){
                                var i = index + 1
                                if (!empty(data['icon' + i + 'old'])) {
                                    return(
                                        <div className="counterCard" key={"counter" + i}>
                                            <div className="grid counterGrid gap20 alignCenter">
                                                <div className="maskImgBg">
                                                    <div className="counterIconSec flex alignCenter justifyCenter">
                                                        <img className="width100" src={ urls.assets + (data['icon' + i + 'old']) } alt={ data['iconAlt' + i] } />
                                                    </div>
                                                </div>
                                                <div className="counterTextSec">
                                                    <p className="cardCount">{ data['count' + i]}</p>
                                                    <p className="cardPara">{ data['countText' + i]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
