import React from 'react'
import Global from '../Global/Global';

export default function Paripartnerssection({data, facilities, id}) {
    const {urls, empty} = Global();
    return (
        <div className="partMain owlTop">
            <div className="maxWidth1300 floatCenter relative">
                <div className="width90 maxWidth1100 floatCenter">
                    <div className="owl-carousel owl-theme partSlider" id={"id" + id} data-autoPlayTime={ data['autoplaytime'] }
                        data-dots={ data['showdots'] }  data-web={ data['webCount'] } data-tab={ data['tabCount'] }
                        data-mob={ data['mobCount'] }>

                            {
                                [...Array(20)].map(function(item, index){
                                    var i = index + 1
                                    if (!empty(data['backLogo' + i + 'old'])) {
                                        return(
                                            
                                            <div className="item" key={"part" + index}>
                                                <div className="partCardMain">
                                                    <a href={ data['logoLink'  + i]} >
                                                        <div className="partIcon floatCenter">
                                                            <img className="imgHover" src= {urls.assets + ( data['frontLogo'  + i + 'old'])} alt={ data['logoAlt'  + i] } />
                                                            <img className="mainLogo" src={urls.assets +(data['backLogo'  + i + 'old'])} alt={ data['logoAlt' + i] } />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                        )
                                    }
                                })
                            }
                            
                    </div>
                </div>
                {
                    data['shownavs'] === "Yes" &&
                    <div className="width100 navBtnMain flex alignCenter justifyBetween absolute">
                        <div className="navBtn left flex alignCenter justifyCenter">
                            <i className="fa-solid fa-arrow-left"></i>
                        </div>
                        <div className="navBtn right flex alignCenter justifyCenter">
                            <i className="fa-solid fa-arrow-right"></i>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
