import React, { useState } from "react";
import Global from "../../Global/Global";

const Profilepage = ({user}) => {
    
    const {callApi} = Global();
  const [profileData, setProfileData] = useState({
    id: user.id,
    name: user.name,
    contact: user.contact,
    email: user.email
  });

  const [passwordData, setPasswordData] = useState({
    id: user.id,
    email: user.email,
    old: "",
    newpass: "",
    cpass: "",
  });

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    const resp = await callApi(profileData, "updateProfileReact", "POST", true)
    if(resp.status === "OK"){
        window.callAlert(resp.message, "successAlert");
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const resp = await callApi(passwordData, "updatePasswordReact", "POST", true)
    if(resp.status === "OK"){
        window.callAlert(resp.message, "successAlert");
    }
  };

  return (
    <div className="userdashboardV2Right">
      <div className="userdashboardV2RightGrid grid grid2 gap10">
        {/* Update Profile Section */}
        <div className="rightSIdeBlocks">
          <p className="blocksHeading">
            <strong>Your Profile</strong>
          </p>
          <form onSubmit={handleProfileSubmit}>
            <div className="userV2Imp">
              <label>
                Your Name <span className="red-txt">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="e.g John Doe"
                required
                value={profileData.name}
                onChange={handleProfileChange}
              />
            </div>
            <div className="userV2Imp">
              <label>
                Your Contact no <span className="red-txt">*</span>
              </label>
              <input
                type="text"
                name="contact"
                placeholder="e.g 919711111111"
                required
                value={profileData.contact}
                onChange={handleProfileChange}
              />
            </div>
            <div className="userV2Imp">
              <label>
                Your Email Address <span className="red-txt">*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="e.g info@example.com"
                required
                value={profileData.email}
                readOnly
                disabled
                style={{ cursor: "not-allowed" }}
              />
            </div>
            <div className="userV2Imp">
              <button className="userV2ImpBtn">
                <strong>UPDATE PROFILE</strong>
              </button>
            </div>
          </form>
        </div>

        {/* Change Password Section */}
        <div className="rightSIdeBlocks">
          <p className="blocksHeading">
            <strong>Change Password</strong>
          </p>
          <form onSubmit={handlePasswordSubmit}>
            <div className="userV2Imp">
              <label>
                Your Old Password <span className="red-txt">*</span>
              </label>
              <input
                type="password"
                name="old"
                placeholder="Enter Your Old Password"
                required
                value={passwordData.old}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="userV2Imp">
              <label>
                Your New Password <span className="red-txt">*</span>
              </label>
              <input
                type="password"
                name="newpass"
                placeholder="Enter Your New Password"
                required
                value={passwordData.newpass}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="userV2Imp">
              <label>
                Confirm New Password <span className="red-txt">*</span>
              </label>
              <input
                type="password"
                name="cpass"
                placeholder="Confirm New Password"
                required
                value={passwordData.cpass}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="userV2Imp">
              <button className="userV2ImpBtn">
                <strong>UPDATE PASSWORD</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
      
    </div>
  );
};

export default Profilepage;
