import React, { useState } from 'react'
import Global from '../Global/Global';

export default function Parisupportsection({ data, list, id }) {
    const [activeTab, setActiveTab] = useState(1);
    const {urls} = Global();

  const renderTabs = () => {
    const tabs = [];
    for (let i = 1; i <= 3; i++) {
      if (data[`tabName${i}`]) {
        tabs.push(
          <div
            key={i}
            className={`supCard ${activeTab === i ? "activeTab" : ""}`}
            onClick={() => setActiveTab(i)}
            data-tab={`tab${i}`}
          >
            <div className="grid cardGrid gap20 alignCenter">
              <div className="iconSec flex alignCenter justifyCenter">
                <i className={data[`tabIcon${i}`]}></i>
              </div>
              <div className="cardTextSec">
                <p className="cardHead">{data[`tabName${i}`]}</p>
              </div>
            </div>
            <div className="hoverDiv"></div>
          </div>
        );
      }
    }
    return tabs;
  };

  const renderTabContent = () => {
    const content = [];
    for (let i = 1; i <= 3; i++) {
      if (data[`cardHeading${i}`]) {
        content.push(
          <div
            key={i}
            className={`botTabSec ${activeTab === i ? "active" : ""} tab${i}`}
            style={{
              backgroundImage: 'url("'+ urls.assets +data[`bgImg${i}old`]+'")',
            }}
          >
            <div className="grid botGrid gap30 alignCenter">
              {/* Left Image Section */}
              <div className="leftImgSec">
                <div className="imgSec">
                  <img
                    className="width100"
                    src={ urls.assets + data[`img${i}old`]}
                    alt={data[`imgAlt${i}`]}
                  />
                </div>
              </div>
              {/* Right Content Section */}
              <div className="botRightSec">
                <p className="cardHead">{data[`cardHeading${i}`]}</p>
                {Array.from({ length: 2 }, (_, j) => j + 1).map((j) =>
                  data[`pointHead${j}${i}`] ? (
                    <div key={j} className="botCardSec">
                      <div className="grid botCardGrid gap30">
                        <div className="botIConSec flex alignCenter justifyCenter">
                          <i className="fa-regular fa-circle-check"></i>
                        </div>
                        <div className="botCardText">
                          <p className="carInnerHead">
                            {data[`pointHead${j}${i}`]}
                          </p>
                          <p className="carInnerPara">
                            {data[`pointPara${j}${i}`]}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
        );
      }
    }
    return content;
  };
  return (
    <div className="supMain">
      {/* Top Section */}
      <div className="width90 maxWidth700 floatCenter textCenter">
        <span className="line"></span>
        <p className="abtHead">{data.smallHead}</p>
        <p className="abtSubHead">{data.mainHeading}</p>
      </div>

      {/* Tab Section */}
      <div className="width90 maxWidth1100 floatCenter">
        {/* Top Tabs */}
        <div className="topTabSec">
          <div className="grid grid3 gap30">{renderTabs()}</div>
        </div>

        {/* Bottom Tab Content */}
        {renderTabContent()}
      </div>
    </div>
  )
}
