import React from 'react'
import Global from '../Global/Global';

export default function Productcard2({product}) {
    
    const {urls} = Global();
  return (
    
    <div className="serCardMain">
      {/* Card Image */}
      <div className="cardImg">
        <img
          className="width100"
          src={ urls.assets + product.image}
          alt={product.name}
        />
      </div>

      {/* Card Information */}
      <div className="cardInfoSec textCenter">
        <div className="maskImg floatCenter">
          <div className="iconInnerBg">
            <div className="iconMainSec flex alignCenter justifyCenter">
              <i className="fa-solid fa-hand-holding-heart"></i>
            </div>
            <div className="rotate"></div>
          </div>
        </div>
        <p className="cardHead line2">{product.name}</p>
        <p className="cardPara line3">
          {product.card_description}
        </p>

        {/* Button Section */}
        <div className="btnMainSec">
          <a href={product.url}>
            <button className="readBtn">
              Read More &nbsp;<i className="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
      <div className="hoverDiv"></div>
    </div>
  )
}
