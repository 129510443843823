import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import { useParams } from "react-router-dom";
import Menu from '../Menu/Menu';
import Global from '../Global/Global';
import Loadingstatic from '../Global/Loadingstatic';
import { useLocation } from 'react-router-dom';

export default function Policy() {
    const location = useLocation(); // Get current page URL
    const { slug } = useParams();
    const {callApi, addCss, addScript, urls, empty} = Global();
    const [loading, setLoading] = useState(true)

    const [policy, setPolicy] = useState(null)
    const [related, setRelated] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact_no: '',
        msg: '',
        required: 'name,contact_no',
        tag: 'Service',
        pageurl: window.location.href,
        service_title: ''
    });
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const resp = await callApi(formData, "formSubmitReact", "POST", true)
        if(resp.status !== undefined && resp.status === "OK"){
            window.callAlert(resp.message, "successAlert")
            window.location.href="/policy/"+resp.enqid
        }
        
    };

    const getBlog = async () => {

        const resp = await callApi({slug: slug}, "getServiceReact", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            setFormData((prevData) => ({
                ...prevData,
                ['service_title']: resp.service.info.title,
            }));
            // console.log(resp.service)
            setPolicy(resp.service)
            // setRelated(resp.related)
            setLoading(false)
        }
        
    }

    useEffect(() => {
        getBlog()
        
        const less = addCss( urls.assets + "assets/common/Blog_Page.css");
        const script = addScript( urls.assets + 'assets/common/Blog_Page.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };
    }, [])
  return (
    <>
        <Menu />
        {
            loading &&
            <div style={{padding:"100px 0"}} >
                <Loadingstatic />
            </div>
        }
        {
            !loading && !empty(policy) &&
            <div className="blogV2">
                {!empty(policy.info.banner_imageold) && (
                    <div className="maxWidth1500 float-center">
                        <img
                            src={ urls.assets + policy.info.banner_imageold}
                            alt={policy.alt2}
                            className="width100"
                            style={{ borderRadius: '10px' }}
                        />
                        <br />
                    </div>
                )}
                <div className="maxWidth1500 float-center">
                    <div className="blogGrid">
                        <div className="blogV2Area">
                            <div className="ck-content" dangerouslySetInnerHTML={{ __html: policy.info.policy }} />
                        </div>
                        <div className="blogV2Side">
                            <div className="stickySidebar">
                                <div className="blogForm">
                                    <p className="center-text blogFormHead">Questions? We’re Here!</p>
                                    <form onSubmit={handleSubmit}>

                                        <div className="blogFormField">
                                            <label>Name<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                placeholder="e.g John Doe"
                                                required
                                            />
                                        </div>
                                        <div className="blogFormField">
                                            <label>Email<span className="text-red"> (Optional)</span></label>
                                            <input
                                                type="text"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                placeholder="e.g info@example.com"
                                            />
                                        </div>
                                        <div className="blogFormField">
                                            <label>Contact No<span className="text-red">*</span></label>
                                            <input
                                                type="number"
                                                name="contact_no"
                                                value={formData.contact_no}
                                                onChange={handleInputChange}
                                                placeholder="e.g +919711111111"
                                                required
                                            />
                                        </div>
                                        <div className="blogFormField">
                                            <label>Message<span className="text-red"> (Optional)</span></label>
                                            <textarea
                                                name="msg"
                                                value={formData.msg}
                                                onChange={handleInputChange}
                                                placeholder="if Any"
                                            />
                                        </div>
                                        <div className="blogFormField">
                                            <button type="submit">Submit Enquiry</button>
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="blogForm" style={{ marginTop: '10px' }}>
                                    <p className="center-text blogFormHead">You May Also Like</p>
                                </div> */}
                                {
                                    !empty(policy.info.policypdfold) &&
                                    <div className="blogForm" style={{ marginTop: '10px' }}>
                                        <div className="blogFormField">
                                            <a href={urls.assets + policy.info.policypdfold} target='_BLANK'>
                                                <button type="submit"><i className='fas fa-download'></i> &nbsp; Download Policy</button>
                                            </a>
                                        </div>
                                    </div>
                                }
                                {
                                    // related.length > 0 &&
                                    // related.map((item, index) => {
                                    //     const formattedDate = new Date(item.updated * 1000).toLocaleDateString("en-GB", {
                                    //         day: "2-digit",
                                    //         month: "short",
                                    //         year: "numeric",
                                    //       });
                                    //     return(
                                    //         <a href={'/blog/' + item['url'] } style={{textDecoration:"none"}} title={item.title} key={"key" + index} >
                                    //             <div className="relatedBlogs">
                                    //                 <div className="relatedBlogImg">
                                    //                     <img src={ urls.assets + (item['img1']) } alt={ ( item['alt1']) } />
                                    //                 </div>
                                    //                 <div className="">
                                    //                     <p className="relatedBlogTitle line3">
                                    //                         <strong>{ item['title'] }</strong>
                                    //                     </p>
                                    //                     <p className="dateRelated">
                                    //                         <small>{formattedDate}</small>
                                    //                     </p>
                                    //                 </div>
                                    //             </div>
                                    //         </a>    
                                    //     )
                                    // })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        
        
        <Footer />
    </>
  )
}
