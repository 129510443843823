import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import './Login.css'
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import OtpInput from 'react-otp-input';

export default function Verifyotp() {
    const {callApi, empty, urls, getUser} = Global();

    const [otp, setOtp] = useState('');
    const [remain, setRemain] = useState(0);
    const [otpSent, setOtpSent] = useState(false)

    const [formData, setFormData] = useState({
        email: localStorage.getItem('useremail'),
    });

    const findUser = async () => {
        const user = await getUser(false)
    }

    let intervalId = null; // Store interval ID

    const startTimer = () => {
      if (intervalId) return; // Prevent multiple intervals
      intervalId = setInterval(() => {
        setRemain((prev) => {
          if (prev <= 1) {
            clearInterval(intervalId); // Clear interval when time reaches 0
            intervalId = null;
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    };

    const sendOtp = async () => {
        const payload = {
            "id": localStorage.getItem('userid'),
            "email": localStorage.getItem('useremail'),
        }
        const otpresp = await callApi(payload, "sendOTPReact", "POST", true);
        if(otpresp.status === "OK"){
            window.callAlert(otpresp.message, "successAlert")
            setOtpSent(true)
            setRemain(60)
            startTimer();
        }
    }

    const verifyOTP = async () => {
        const payload = {
            "id": localStorage.getItem('userid'),
            "email": localStorage.getItem('useremail'),
            "otp": otp,
        }
        const otpresp = await callApi(payload, "verifyOTPReact", "POST", true);
        if(otpresp.status === "OK"){
            window.callAlert(otpresp.message, "successAlert")
            window.location.href = "/user/dashboard"   
        }
    }

    
    useEffect(() => {
        if(empty(localStorage.getItem('userid'))){
            window.location.href = "/login"
        }
        findUser()
        
    }, [])

  return (
    <>
        <Menu />
        
        <div className='loginReact'>
            <div className='loginBox float-center'>
                <h2 className='center-text'>
                    Account Verification Pending
                </h2>
                <p className='center-text' style={{padding:"10px"}}>Please verify your account by entering the OTP sent to your registered contact.</p>
                
                    <div className='inputBox'>
                        <label>Email<span className='text-red red-text'>*</span></label>
                        <input
                            type="email"
                            name="email"
                            className='inputF'
                            value={formData.email}
                            // onChange={handleInputChange}
                            placeholder="e.g info@example.com"
                            required
                            readOnly
                            style={{cursor:"no-drop"}}
                        />
                    </div>
                    {
                        otpSent &&
                        <div className='inputBox'>
                            <br />
                            <label>Enter OTP<span className='text-red red-text'>*</span></label>
                            
                            <div className='otpBOXES'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} className='inputF' />}
                                />
                            </div>
                        </div>
                    }
                    
                    {
                        otpSent &&
                        <>
                            <div className='inputBox'>
                                <button className='btnClas' onClick={() => verifyOTP()}>Verify OTP?</button>
                            </div>
                                    
                            <p className='center-text resendOTP'>
                                Didn't Received OTP? &nbsp;
                                {
                                    remain === 0 &&
                                    <span onClick={() => sendOtp()}>RESEND</span>
                                }
                                {
                                    remain > 0 &&
                                    <span style={{color:"#000000AA"}}>RESEND ({remain}s)</span>
                                }
                            </p>
                        </>
                    }
                    {
                        !otpSent &&
                        <div className='inputBox'>
                            <button className='btnClas' onClick={() => sendOtp()}>Send OTP?</button>
                        </div>
                    }
                
            </div>
        </div>
        <Footer />
    </>
  )
}
