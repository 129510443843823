import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import { useParams } from "react-router-dom";
import Menu from '../Menu/Menu';
import Global from '../Global/Global';

import Loadingstatic from '../Global/Loadingstatic';

export default function Donation(props) {
    const { slug } = useParams();
    const {callApi, addLessFiles, addScript, urls, empty} = Global();
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(0)
    const [slabs, setSlabs] = useState([])
    const [logo, setLogo] = useState("")

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")

    const [product, setProduct] = useState(null)

    const getProduct = async () => {
        const resp = await callApi({"slug": slug}, "getProductReact", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            if(empty(resp.redirect)){
                setProduct(resp.product)
                setLogo(resp.logo)
                if(!empty(resp.product.formdata.slabs)){
                    var sl = resp.product.formdata.slabs.split(",");
                    setSlabs(sl)
                    setAmount(parseInt(sl[0]))
                }
            }
        }
        setLoading(false)
    }
    const donationDone = async (response) => {
        
        window.callAlert("Loading Please Wait...", "infoAlert", 1000000);

        var payid = response.razorpay_payment_id
        const resp = await callApi({"name": name, "email": email, "contact": contact, "payid": payid, "postid": product.id, "paid": amount, "type": "Donation"}, "makeDonation", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            window.callAlert("Payment Successful Redirecting Please Wait...", "infoSuccess");
            window.location.href="/success/" + resp.donationid
        }
    }

    const callRazorPay = () => {
        window.callAlert("Loading Please Wait...", "infoAlert", 1000000);
        const options = {
            key: "rzp_live_MBhGSK6P04PORE", // Replace with your Razorpay Key
            amount: amount * 100, // Amount in paisa (e.g., ₹500 = 50000)
            currency: "INR",
            name: "Pari Foundation",
            description: product.pname,
            handler: function (response) {
                donationDone(response)
            //   console.log(response);
              // Handle success here
            },
            prefill: {
              name: name,
              email: email,
              contact: contact,
            },
            theme: {
              color: "#3399cc",
            },
          };
          
          const razorpay = new window.Razorpay(options);
          razorpay.open();
            
          // Attach a payment failure handler
            razorpay.on("payment.failed", function (response) {
                window.$(".alerts").fadeOut();
            });
            razorpay.on("payment.canceled", function (response) {
                window.$(".alerts").fadeOut();
            });
    }
    
    const makeDonation = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneRegex = /^\+?[0-9]{1,4}?[0-9]{9}$/;

        if(amount < 1){
            window.callAlert("Amount Cannot Be Less Than 1", "warningAlert")
        }else if(name === ""){
            window.callAlert("Name Cannot be Null!!!", "warningAlert")
        }else if(email === ""){
            window.callAlert("Email Cannot be Null!!!", "warningAlert")
        }else if(!emailRegex.test(email)){
            window.callAlert("Please Enter Valid EMail Address!!!", "warningAlert")
        }else if(contact === ""){
            window.callAlert("Contact Cannot be Null!!!", "warningAlert")
        }else if(!phoneRegex.test(contact)){
            window.callAlert("Invalid Contact No!!!", "warningAlert")
        }else{
            callRazorPay()
        }
        
    }

    useEffect(() => {
        getProduct()
        
        
        const less = addLessFiles( urls.assets + "assets/less/campaign-donation-page.less");
        const script = addScript( urls.assets + 'assets/js/pages/campaign-donation-page.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };

    }, [])
  return (
    <>
        <Menu />
        <div className='p_22_s190'>
        {
            loading &&
            <div style={{padding:"100px 0"}} >
                <Loadingstatic />
            </div>
        }
        {
            !loading &&
            product !== null &&
            <div className="sponMain">
                <div className="width90 maxWidth1100 floatCenter">
                    <div className="grid sponGrid gap30" style={{alignItems:"flex-start"}}>
                    {/* Left Section */}
                    <div className="leftMain">
                        <p className="mainHeading">Let's Get Started</p>

                        {/* Amount Section */}
                        {
                            !empty(product.goal) &&
                            <div className="amountSec flex alignCenter justifyBetween gap20">
                                <p className="amtHead">Need Amount</p>
                                <p className="amtMain">
                                    <i className="fa-solid fa-indian-rupee-sign"></i> {product.goal}
                                </p>
                            </div>
                        }

                        <div className="multiAmt grid cusGrid alignCenter gap10">
                            <div className="amtSec grid grid5 alignCenter gap10">
                                {
                                    slabs.length > 0 &&
                                    slabs.map((item, index) => {
                                        var am = parseInt(item);
                                        return(
                                            <div className={"amt " + ((amount === am) ? ' active' : '')} key={"item" + index} onClick={() => setAmount(am)}>
                                                <i className="fa-solid fa-indian-rupee-sign"></i> {am}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="cusAmtSec">
                                <div className="amt">
                                    <input type="number" placeholder="Custom Amount" value={amount} onChange={(e) => setAmount((e.target.value > 100000) ? 100000 : e.target.value)} />
                                </div>
                            </div>
                        </div>

                        {/* Personal Info Section */}
                        <p className="mainHeading">Personal Info</p>
                        <div className="fullNameSec grid grid2 gap20">
                        <div className="inputSec">
                            <label>
                            Your Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <input type="text" placeholder="e.g John Doe" name='name' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="inputSec">
                            <label>
                            Contact No<span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <input type="number" placeholder="e.g 919711xxxxxx"  name='contact' value={contact} onChange={(e) => setContact(e.target.value)} />
                        </div>
                        </div>
                        <div className="inputSec">
                        <label>
                            Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <input type="email" placeholder="e.g info@example" name='email'  value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <p className="totalAmt">
                        Donation Total: <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        { amount }
                        </p>

                        {/* Donate Button */}
                        {
                            amount > 0 && (product.raisedper < 100) && 
                            <button className="donateBtnSec flex alignCenter" onClick={() => makeDonation()}>
                                <span>
                                    <i className="fa-solid fa-angles-right"></i>
                                </span>
                                &nbsp;&nbsp; Donate Now
                            </button>
                        }
                        {
                            product.raisedper >= 100 &&
                            <div className='ck-content'>
                                <p style={{color:"red", marginTop:"50px", textAlign:"center"}}>
                                    <strong>
                                        Thank you for your generosity! The donation goal has already been reached, and we are no longer accepting additional contributions for this cause. We deeply appreciate your willingness to support and encourage you to stay tuned for future opportunities to make a difference.
                                    </strong>
                                </p>
                            </div>
                        }
                    </div>

                    {/* Right Section */}
                    
                    <div className="rightMain relative">
                            {/* Card Main Section */}
                            <div className="cardMain">
                                <div className="cardImgSec relative">
                                <img
                                    className="width100"
                                    src={urls.assets + product.defaultImage.image}
                                    alt="Event"
                                />
                                {
                                    !empty(product.goal) &&
                                    <p className="percent">{product.raisedper}%</p>
                                }
                                </div>
                                <div className="cardTextSec">
                                    
                                {
                                    !empty(product.goal) &&
                                        <div className="progressBar">
                                            <div className="outerDiv">
                                            <div className="innerDiv" style={{width:product.raisedper+'%'}}></div>
                                            </div>
                                        </div>
                                }
                                {
                                    !empty(product.goal) &&
                                    <div className="cardInfoInner">
                                        <div className="grid grid2 gap10">
                                        <div className="innerSec">
                                            <p className="greyPara">Raised</p>
                                            <p className="donatePara">₹{product.raised}</p>
                                        </div>
                                        <div className="innerSec">
                                            <p className="greyPara">Goal</p>
                                            <p className="donatePara">₹{product.goal}</p>
                                        </div>
                                        </div>
                                    </div>
                                }
                                <p className="cardHead">{product.pname}</p>
                                <p className="cardPara">
                                    {product.formdata.card_description}
                                </p>

                                <div className="cardInfoInner padding10" style={{padding:"10px 0"}}>
                                    <div className="grid grid2 gap10 textCenter">
                                    <div className="innerSec">
                                        <p className="greyPara">Start Date</p>
                                        <p className="donatePara">{product.start_date_string}</p>
                                    </div>
                                    <div className="innerSec">
                                        <p className="greyPara">End Date</p>
                                        <p className="donatePara">{ empty(product.end_date_string) ? '-' : product.end_date_string}</p>
                                    </div>
                                    </div>
                                </div>
                                
                                </div>
                            </div>

                            {
                                !empty(product.formdata.helplineno) &&
                                <div className="orgMain">
                                    {/* <p style={{fontSize:"20px", paddingBottom:"10px"}} className='center-text' >HELPLINE NO</p> */}
                                    {
                                        !empty(logo) &&
                                        <div className="logoSec textCenter">
                                            <img
                                                className="width80"
                                                src={urls.assets + logo}
                                                alt="Pari Foundation"
                                            />
                                        </div>
                                    }
                                    
                                    {
                                        product.formdata.helplineno.split(",").map((item, index) => {
                                                return(
                                                    <p className="noSec" key={"help"+index} style={{paddingTop:"10px"}}>
                                                        <a href={ 'tel:' + item} className="donateBtnSec flex alignCenter">
                                                            <span>
                                                            <i className="fa-solid fa-phone"></i>
                                                            </span>
                                                            &nbsp;&nbsp;{item}
                                                        </a>
                                                    </p>
                                                )
                                            })
                                        }
                                    
                                    
                                </div>
                            }

                        </div>
                    </div>
                </div>
                </div>
            }
        </div>
        <Footer />
    </>
  )
}
