import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import Ticker from './Ticker';
import Menulist from './Menulist';
import Mobilemenu from './Mobilemenu';


export default function Menu() {

  const {callApi} = Global();
  const [loading, setLoading] = useState(true)
  const [links, setLinks] = useState([])
  const [rightArray, setRightArray] = useState([])
  const [social, setSocial] = useState(null)
  const [menu, setMenu] = useState(null)
  const { innerWidth: width, innerHeight: height } = window;

  const getMenu = async () => {
      const resp = await callApi({}, "getMenuData", "POST")
      if(resp.status !== undefined && resp.status === "OK"){
        setLinks(resp.social)
        setSocial(resp.data)
        setMenu(resp.menu)
        setRightArray(resp.rightArray)
        setLoading(false)
      }
  }

  useEffect(() => {
    getMenu()
  }, [])

  return (
    <>
      {
        !loading &&
        <>
        {
          width > 900 &&
          <section className="myMenu">
            <Ticker social={social} media={links} />
            <Menulist social={social}  menu={menu} rightArray={rightArray} />
          </section>
        }
        {
          width <= 900 &&
          <section className="myMenu">
            <Mobilemenu social={social}  menu={menu} rightArray={rightArray} />
          </section>
        }
        </>
      }
    </>
  )
}
