import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import './Login.css'
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import Sidebar from './Sidebar';
import Loadingstatic from '../Global/Loadingstatic';
import Profilepage from './Dashboard/Profile';

export default function Profile() {
    const {callApi, empty, urls, getUser, addCss} = Global();
    const [user, setUser] = useState("")
    const findUser = async () => {
        const user = await getUser()
        setUser(user)
        window.feather.replace()
    }
    
    useEffect(() => {
        if(empty(localStorage.getItem('userid'))){
            window.location.href = "/login"
        }
        findUser()

        const less = addCss( urls.assets + "assets/css/userdashboard.css");
        return () => {
            document.head.removeChild(less);
        };
        
        
    }, [])

  return (
    <>
        <Menu />
            <div className="userDashboardV2">
                {
                    user !== "" &&
                    <div className="width90  maxWidth1500 float-center userDashboardV2Grid">
                        <Sidebar active="Profile" userName={user.name}  />
                        <Profilepage user={user} />
                    </div>
                }
                {
                    user === "" &&
                    <div style={{height:"50vh"}}>
                        <Loadingstatic />
                    </div>
                }
            </div>
            
        <Footer />
    </>
  )
}
