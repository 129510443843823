import React from 'react';
import Global from '../Global/Global';

const Pariwhysection = ({data, list, id}) => {
    const {urls} = Global();
    const renderCards = () => {
        const cards = [];
        for (let i = 1; i <= 5; i++) {
            if (data[`cardHeading${i}`]) {
                const points = [];
                for (let j = 1; j <= 5; j++) {
                    if (data[`point${i}${j}`]) {
                        points.push(
                            <div className="points" key={`point-${i}-${j}`}>
                                <div className="grid pointGrid gap20">
                                    <div className="iconSec">
                                        <i className="fa-regular fa-circle-check"></i>
                                    </div>
                                    <div className="cardPara">{data[`point${i}${j}`]}</div>
                                </div>
                            </div>
                        );
                    }
                }
                cards.push(
                    <div className="whyCard" key={`card-${i}`}>
                        <div className="hoverDiv"></div>
                        <div className="grid cardGrid gap20 alignCenter">
                            <div className="iconMainSec flex alignCenter justifyCenter">
                                <img
                                    className="width60"
                                    src={ urls.assets + data[`iconSec${i}old`]}
                                    alt={data[`iconAlt${i}`]}
                                />
                            </div>
                            <div className="cardInfoSec">
                                <p className="cardHead">{data[`cardHeading${i}`]}</p>
                                {points}
                            </div>
                            <div className="linkIconSec">
                                <a href={data[`cardLink${i}`]}>
                                    <div className="linkIconMain flex alignCenter justifyCenter">
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return cards;
    };

    return (
        <div
            className="whyMain"
            style={{
                backgroundImage: `linear-gradient(#00000080, #00000080), url(${urls.assets + data.bgImgold})`,
            }}
        >
            <div className="width90 maxWidth1100 floatCenter">
                <div className="grid whyGrid gap30">
                    <div className="blankDiv"></div>
                    <div className="rightSec">
                        <div className="animImg absolute">
                            <img
                                className="width100"
                                src={urls.assets + data.animateImgold}
                                alt={data.animateImgAlt}
                            />
                        </div>
                        <div className="whyHeadSec">
                            <span className="line"></span>
                            <p className="abtHead">{data.smallHead}</p>
                            <p className="abtSubHead" dangerouslySetInnerHTML={{ __html: data.mainHeading }} />
                            <p className="abtPara">{data.subPara}</p>
                        </div>
                        {renderCards()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pariwhysection;
