import React from 'react'
import Global from '../Global/Global';

export default function Productcard3({product}) {
    const {urls, empty} = Global();
    // Format the date
  const formattedDate = new Date(product.date * 1000).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return (
    
    <div className="card1Sec">
      <a href={product.url} title={product.name}>
        <div className="cardMain relative">
          <div className="cardImgSec">
            <img className="width100" src={ urls.assets + product.image} alt={product.name} />
          </div>
          {/* <div className="tag">Food & Water</div> */}
          <div className="cardInfoSec">
          {
                !empty(product.goal) &&
              
              <div className="progressMainSec">
                <div className="progressText flex alignCenter justifyBetween">
                    <p className="goalSec">
                      Goal: <span>₹{product.goal}</span>
                    </p>
                </div>
                
                  <div className="progressBar">
                    <div className="outerDiv">
                      <div className="innerDiv" style={{width:product.raisedper}}></div>
                    </div>
                  </div>
                
              </div>
          }
            <p className="cardHeading line2">{product.name}</p>
            <p className="cardSubPara">
                {product.card_description}
            </p>

            <div className="cardInnerGrid">
              <div className="grid grid2">
                <div className="dateSec">
                  <div className="grid dateGrid alignCenter gap10">
                    <div className="iconSec flex alignCenter justifyCenter">
                      <i className="fa-solid fa-calendar-days"></i>
                    </div>
                    <div className="datTextSec">
                      <p className="innercardHead">Date:</p>
                      <p className="innercardPara">{formattedDate}</p>
                    </div>
                  </div>
                </div>
                <div className="postSec">
                  <div className="grid dateGrid alignCenter gap10">
                    <div className="iconSec flex alignCenter justifyCenter">
                      <i className="fa-solid fa-user-tie"></i>
                    </div>
                    <div className="datTextSec">
                      <p className="innercardHead">By:</p>
                      <p className="innercardPara">Admin</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}
