import React, { useState } from 'react'
import Global from '../Global/Global';

export default function Templatesection({data, list, id}) {
    const {urls} = Global();
    

  return (
    <>
    <div className="tempMain">
      {/* Heading Section */}
      <div className="width90 maxWidth700 floatCenter textCenter">
        <span className="line"></span>
        <p className="abtHead">{data.smallHead}</p>
        <p className="abtSubHead">{data.mainHeading}</p>
        <p className="abtPara">{data.subPara}</p>
      </div>

      {/* Document Grid Section */}
      <div className="width90 maxWidth1300 floatCenter">
        <div className="grid grid4 gap30">
          {Array.from({ length: 100 }, (_, index) => {
            const i = index + 1;

            // Check if data exists for the current item
            if (!data[`fileUpload${i}old`]) return null;

            return (
              <div className="docCard" key={i}>
                <div className="cardImg">
                  <img
                    className="width100"
                    src={ urls.assets + data[`img${i}old`]}
                    alt={data[`cardHeading${i}`]}
                  />
                </div>
                <div className="cardInnerSec">
                  <p className="cardHead line2">{data[`cardHeading${i}`]}</p>
                  <p className="cardPara line3">{data[`cardPara${i}`]}</p>
                  <div className="btnSec">
                    <a
                      href={data[`fileUpload${i}old`]}
                      title="Download"
                      download
                    >
                      <button className="view">
                        {data[`btnText${i}`]} &nbsp;&nbsp;{" "}
                        <i className="fa-solid fa-circle-down"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </>
  )
}
