import React from 'react'
export default function Paricta({data, facilities, id}) {
    
  return (
        <div className="width100 ctaMain">
            <div className="width90 maxWidth1300 floatCenter">
                <div className="grid ctaGrid gap30 alignCenter">
                    <div className="ctaLeftSec">
                        <p className="ctaHeading">{ data['leftHeading'] }</p>
                    </div>
                    <div className="ctaRightSec">
                        <div className="orSec flex alignCenter justifyCenter">Or</div>
                        <div className="grid grid2 alignCenter height100">
                            <a href="{{$data['btnLeftLink']}}" className="height100">
                                <div className="donationSec flex alignCenter justifyCenter">
                                    <p className="donatText">{ data['btnLeft'] }</p>
                                </div>
                            </a>
                            <a href="{{$data['btnRightLink']}}" className="height100">
                                <div className="volunteerSec flex alignCenter justifyCenter">
                                    <p className="donatText textCenter">{ data['btnRight'] }</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
