import React from 'react'
import Global from '../Global/Global';

export default function Testimonialsection({data, facilities, id}) {
    const {urls} = Global();
    const testimonials = [];
    for (let i = 1; i <= 20; i++) {
        if (data[`comment${i}`]) {
        testimonials.push({
            name: data[`name${i}`],
            designation: data[`desig${i}`],
            comment: data[`comment${i}`],
            profileImg: data[`profile${i}old`],
            stars: data[`stars${i}`],
        });
        }
    }
  return (
    <div className="width100 testiMain owlTop">
      <div className="mainTextSec width90 maxWidth700 floatCenter textCenter">
        <p className="mainHeading" dangerouslySetInnerHTML={{ __html: data.popProHeading }}></p>
        <p className="mainSubPara" dangerouslySetInnerHTML={{ __html: data.popProSubPara }}></p>
      </div>

      <div className="width100 relative">
        <div className="width90 maxWidth1100 floatCenter">
          <div
            className="owl-carousel owl-theme testiSlider"
            id={`id${data.id}`}
            data-autoplaytime={data.autoplaytime}
            data-dots={data.showdots}
            data-web={data.webCount}
            data-tab={data.tabCount}
            data-mob={data.mobCount}
          >
            {testimonials.map((testimonial, index) => (
              <div className="item" key={index}>
                <div className="testiCardMainSec textCenter">
                  <div className="quoteIconMain flex justifyEnd">
                    <i className="fa-solid fa-quote-right"></i>
                  </div>
                  <div className="cardImgMain flex alignCenter justifyCenter floatCenter">
                    <img className="width100" src={ urls.assets + testimonial.profileImg} alt={testimonial.name} />
                  </div>
                  <p className="testiCardName">{testimonial.name}</p>
                  <p className="testiCardDesig">{testimonial.designation}</p>
                  <div className="starMainSec flex alignCenter justifyCenter">
                    {Array.from({ length: testimonial.stars }, (_, i) => (
                      <div className="starSec flex alignCenter justifyCenter" key={i}>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    ))}
                  </div>
                  <p className="testiCardComment">{testimonial.comment}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {data.shownavs === "Yes" && (
          <div className="width90 maxWidth1300 floatCenter arrowMainSec flex alignCenter justifyBetween absolute">
            <div className="arrowLeftIconSec left flex alignCenter justifyCenter">
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div className="arrowRightIconSec right flex alignCenter justifyCenter">
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
