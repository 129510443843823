import React from 'react'
import Global from '../Global/Global';

export default function Blogcard({item}) {
    const {urls} = Global();
    const formattedDate = new Date(item.updated * 1000).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
  return (
    <div className="blogCard">
      <a href={item.url} title={item.title}>
        <div className="blogImgSec">
          <img className="width100" src={ urls.assets + item.img1} alt={item.alt1} />
        </div>
        <div className="blogTextSec">
          <div className="dateSec">{formattedDate}</div>
          <p className="cardHeading line2">{item.title}</p>
          <p className="cardSubPara line3">{item.shortpara}</p>
        </div>
        <div className="btnSec flex alignCenter justifyBetween">
          <p>Read More</p>
          <div className="arrow flex alignCenter justifyCenter">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div>
      </a>
    </div>
  )
}
