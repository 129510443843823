import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';

export default function Policydocumentsection({data, list, id}) {
    const {urls, callApi} = Global();
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(false)

    const getServices = async () => {
      if(!loading){
        setLoading(true)
        const resp = await callApi({}, "getServicesReact", "POST", true)
        if(resp.status !== undefined && resp.status === "OK"){
          setServices(resp.services)
        }
        setLoading(false)
      }
    }

    useEffect(() => {
      getServices()
    }, [])

  return (
    <>
      {/* Document Section */}
      <div className="docMain">
        <div className="width90 maxWidth700 floatCenter textCenter">
          <span className="line"></span>
          <p className="abtHead">{data.smallHead}</p>
          <p className="abtSubHead">{data.mainHeading}</p>
          <p className="abtPara">{data.subPara}</p>
        </div>

        <div className="width90 maxWidth1300 floatCenter">
          <div className="grid grid3 gap30">

{
  services.length > 0 &&
  services.map((item, index) => {
    return(
      <div key={index} className="docCard">
        <div className="cardImg">
          <img
            className="width100"
            src={ urls.assets + item['info']['card_imageold']}
            alt={item['info']['title']}
          />
        </div>
        <div className="cardInnerSec">
          <p className="cardHead line2">{item['info']['title']}</p>
          <p className="cardPara line3">{item['info']['card_description']}</p>
          <div className="btnSec grid gap20">
            <a
              href={ '/policies/' + item['pageid'] }
              title="View Policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="view">View Policy</button>
            </a>
          </div>
        </div>
      </div>
    )
  })
}

            {/* {Array.from({ length: 100 }, (_, i) => i + 1).map((i) => {
              const leftBtnPdfKey = `leftBtnPdf${i}old`;
              const imgKey = `img${i}old`;
              const cardHeadKey = `cardHead${i}`;
              const cardParaKey = `cardPara${i}`;
              const leftBtnTextKey = `leftBtnText${i}`;
              const rightBtnTextKey = `rightBtnText${i}`;

              if (data[leftBtnPdfKey]) {
                return (
                  <div key={i} className="docCard">
                    <div className="cardImg">
                      <img
                        className="width100"
                        src={ urls.assets + data[imgKey]}
                        alt={data[cardHeadKey]}
                      />
                    </div>
                    <div className="cardInnerSec">
                      <p className="cardHead line2">{data[cardHeadKey]}</p>
                      <p className="cardPara line3">{data[cardParaKey]}</p>
                      <div className="btnSec grid grid2 gap20">
                        <a
                          href={ urls.assets + data[leftBtnPdfKey]}
                          title="View Policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="view">{data[leftBtnTextKey]}</button>
                        </a>
                        <button
                          className="feedback"
                          onClick={() => handleFeedbackClick(data[cardHeadKey])}
                        >
                          {data[rightBtnTextKey]}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })} */}
          </div>
        </div>
      </div>

    </>
  )
}
