import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import './Login.css'
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import Sidebar from './Sidebar';

export default function Dashboard() {
    const {empty, urls, getUser, addCss} = Global();

    const findUser = async () => {
        const user = await getUser()
        window.feather.replace()
    }
    const handleLogout = () => {
        localStorage.removeItem('userid')
        localStorage.removeItem('useremail')
        window.callAlert("Logged out!", "successAlert")
        window.location.href="/login"
    };

    useEffect(() => {
        if(empty(localStorage.getItem('userid'))){
            window.location.href = "/login"
        }
        findUser()

        const less = addCss( urls.assets + "assets/css/userdashboard.css");
        return () => {
            document.head.removeChild(less);
        };
        
        
    }, [])

  return (
    <>
        <Menu />
            <div className="userDashboardV2">
                <div className="width90 float-center" style={{ maxWidth: "1000px" }}>
                    <div className="grid grid3 gap10">
                    <div className="rightSIdeBlocks forDashboard">
                        <a href="/user/profile" title="My Profile">
                        <div className="center-text">
                            <div className="dahsboRdIcons">
                            <i data-feather="user"></i>
                            </div>
                        </div>
                        <p className="center-text">
                            <strong>Manage Your Profile</strong>
                        </p>
                        <p className="center-text">
                            <small>
                            Easily update and customize your profile details to keep your
                            account up-to-date and personalized.
                            </small>
                        </p>
                        </a>
                    </div>

                    {/* <div className="rightSIdeBlocks forDashboard">
                        <a href="/user/profile" title="Change Password">
                        <div className="center-text">
                            <div className="dahsboRdIcons">
                            <i data-feather="key"></i>
                            </div>
                        </div>
                        <p className="center-text">
                            <strong>Change Password</strong>
                        </p>
                        <p className="center-text">
                            <small>
                            Keep your account safe by regularly updating your password in
                            just a few simple steps.
                            </small>
                        </p>
                        </a>
                    </div> */}

                    <div className="rightSIdeBlocks forDashboard">
                        <a href="/user/donations" title="My Orders">
                        <div className="center-text">
                            <div className="dahsboRdIcons">
                            <i data-feather="heart"></i>
                            </div>
                        </div>
                        <p className="center-text">
                            <strong>Your Donations</strong>
                        </p>
                        <p className="center-text">
                            <small>
                            Your donations make a meaningful impact, helping us support those in need.
                            </small>
                        </p>
                        </a>
                    </div>

                    <div className="rightSIdeBlocks forDashboard">
                        <a
                        href="#"
                        className="logoutClass"
                        title="Logout"
                        onClick={handleLogout}
                        >
                        <div className="center-text">
                            <div className="dahsboRdIcons">
                            <i data-feather="log-out"></i>
                            </div>
                        </div>
                        <p className="center-text">
                            <strong>Log Out of Your Account</strong>
                        </p>
                        <p className="center-text">
                            <small>
                            Securely log out to protect your account and personal
                            information.
                            </small>
                        </p>
                        </a>
                    </div>
                    </div>
                </div>
                </div>
        <Footer />
    </>
  )
}
