import React from 'react'
import Global from '../Global/Global';


export default function Parivideosection({data, facilities, id}) {
    
    const {urls, empty} = Global();

    return (
        <div className="maskImgSec" style={{mask: 'url("'+urls.assets + data['maskImgold'] + '")'}} >
            <div className="width100 vidMainSec relative"  style={{backgroundImage: 'url("'+urls.assets + data['bgImgold'] + '")'}} >
                <div className="absolute heartImg">
                    <img className="width100" src={ urls.assets + data['animateImgold']} alt={ data['animateImgAlt'] } />
                </div>
                <div className="width90 maxWidth700 floatCenter textCenter">
                    {
                        !empty(data['playBtnLink']) &&
                        <a href={ data['playBtnLink'] }  title="Play Now">
                            <div className="vidBtn floatCenter flex alignCenter justifyCenter">
                                <div className="blinkDiv"></div>
                                <i className="fa-solid fa-play"></i>
                            </div>
                        </a>
                    }

                    <p className="vidHead">{ data['smallHead'] }</p>
                    <p className="vidSubHead">{ data['mainHeading'] }</p>
                    <p className="vidPara">{ data['subPara'] }</p>

                    <div className="disMoreMainSec">
                        <a href={ data['btnLink'] } title={ data['buttonText'] }>
                            <button className="discoverBtn">
                                { data['buttonText'] }
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
