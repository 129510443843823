import React from 'react'
import Global from '../Global/Global';

export default function Helplinesection({data, list, id}) {
    const {urls} = Global();
    
  return (
    <div className="helplineSec">
      <div className="width90 maxWidth1300 floatCenter">
        <div className="locSearch flex gap10 alignCenter">
          <p className="searchText">Search Any</p>
          <input type="text" placeholder="Ex: Adarsh Nagar Police Station" />
          <button>Search</button>
        </div>
        <div className="jobCardMainSec">
          {Array.from({ length: 50 }, (_, i) => i + 1).map((i) => {
            const cardNameKey = `cardName${i}`;
            const cardParaKey = `cardPara${i}`;
            const addressKey = `address${i}`;
            const phoneKeys = Array.from({ length: 3 }, (_, j) => `num${j + 1}${i}`);
            const emailKeys = Array.from({ length: 3 }, (_, j) => `email${j + 1}${i}`);

            if (data[cardNameKey]) {
              return (
                <div key={i} className="jobCard">
                  <div className="grid jobInnerGrid gap20 alignCenter">
                    <div className="jobDetailInnerMain">
                      <p className="jobName">{data[cardNameKey]}</p>
                      <p className="subPara">{data[cardParaKey]}</p>
                      <div className="jobInfoMainSec">
                        {phoneKeys.map(
                          (phoneKey) =>
                            data[phoneKey] && (
                              <p key={phoneKey} className="jobDesti">
                                <i className="fa-solid fa-phone"></i> {data[phoneKey]}
                              </p>
                            )
                        )}
                        {emailKeys.map(
                          (emailKey) =>
                            data[emailKey] && (
                              <p key={emailKey} className="jobProfile">
                                <i className="fa-solid fa-envelope"></i> {data[emailKey]}
                              </p>
                            )
                        )}
                        {data[addressKey] && (
                          <p className="jobPosted">
                            <i className="fa-solid fa-location-dot"></i> {data[addressKey]}
                          </p>
                        )}
                      </div>
                      <div className="alertSec">
                        <div className="alert textCenter">Copied</div>
                      </div>
                    </div>
                    <div className="applyBtn floatCenter">
                      {data[`num1${i}`] && (
                        <button className="copyBtn">
                          <i className="fa-solid fa-phone"></i> {data[`num1${i}`]}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  )
}
