import React from 'react'
import Global from '../Global/Global';

export default function Pariabout({data, facilities, id}) {
    
    const {getBanners, urls, empty} = Global();
    
  return (
    <div className="width100 aboutMain">
        <div className="width90 maxWidth1100 floatCenter">
            <div className="grid grid2 gap50">
                <div className="leftImgSec relative">
                    <div className="zigzag absolute">
                        <img className="width100"
                            src={ urls.assets + data['zigzagImgold']} alt={data['zigzagImgAlt']} />
                    </div>
                    <div className="box absolute">
                        <img className="width100"
                            src={ urls.assets + data['boxImgold']} alt={data['boxImgAlt']} />
                    </div>
                    <div className="arrow absolute">
                        <img className="width100"
                            src={ urls.assets + data['arrowImgold']} alt={data['arrowImgAlt']} />
                    </div>
                    <div className="mainImgSec floatRight">
                        <img className="width100" src={ urls.assets + data['mainImgold']}
                            alt={data['bgImgAlt']} />
                    </div>
                    <div className="imgTwo absolute">
                        <div className="grid grid2 imgTwoSec">
                            <div className="smallImg">
                                <img className="" src={ urls.assets + data['frontImgold']}
                                    alt={data['frontImgAlt']} />
                            </div>
                            <div className="leftBtnSec">
                                <div className="grid leftBtnGrid alignCenter gap20">
                                    <div className="imgLeftBtnSec">
                                        <a href={data['leftBtnLink']}>
                                            <div className="playBtnSec flex alignCenter justifyCenter">
                                                <i className="fa-solid fa-play"></i>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="playRightSec">
                                        {data['leftBtnText']}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightTextSec">
                    <div className="abtHeadSec">
                        <span className="line"></span>
                        <p className="abtHead">{data['smallHead']}</p>
                        <p className="abtSubHead">{data['mainHeading']}</p>
                        <p className="abtPara">{data['subPara']}</p>

                        <div className="abtCardSec">
                            <div className="grid grid2 gap20">

                            {
                                [...Array(2)].map(function(item, index){
                                    var i = index + 1
                                    if (!empty(data['cardInnerIcon'+i+'old'])) {
                                        return(
                                            <div className="abtCard" key={"abcard" + index}>
                                                <div className="grid abtCardGrid gap20 alignCenter">
                                                    <div className="maskImgBg">
                                                        <div className="abtIconSec flex alignCenter justifyCenter">
                                                            <img className="width100" src={ urls.assets + data['cardInnerIcon' + i + 'old']} alt={data['cardInnerIconAlt' + i]} />
                                                        </div>
                                                    </div>
                                                    <div className="abtCardTextSec">
                                                        <p className="abtCardHead">{data['cardHeading' + i]}</p>
                                                    </div>
                                                </div>

                                                
                                                {
                                                    [...Array(6)].map(function(item2, index2){
                                                        var j = index2 + 1
                                                        if (!empty(data['card' + i + 'point' + j])) {
                                                            return(

                                                                <div className="grid pointGrid gap10 alignCenter marginTop10" key={"carpoint" + j}>
                                                                    <div className="pointIcon flex alignCenter justifyCenter">
                                                                        <i className="fa-regular fa-circle-check"></i>
                                                                    </div>
                                                                    <div className="pointText">
                                                                        <p className="points">{data['card' + i +'point' + j]}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                                
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="lineSec"></div>
                    {
                        [...Array(6)].map(function(item, index){
                            var i = index + 1
                            if (!empty(data['point'+i])) {
                                return(
                                    
                                    <div className="pointsSec marginBot10" key={"point"+index}>
                                        <div className="grid pointGrid gap10 alignCenter">
                                            <div className="pointIcon flex alignCenter justifyCenter">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </div>
                                            <div className="pointText">
                                                <p className="points" dangerouslySetInnerHTML={{__html: data['point' + i]}}>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            })
                    }


                        <div className="abtMoreBtnSec">
                            <a href={data['btnLink']}>
                                <button className="abtMoreBtn">
                                    {data['btnText']}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
