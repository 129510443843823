import React, { useState } from 'react'
import Global from '../Global/Global';

export default function Mediasection({data, list, id}) {
    const {urls} = Global();
    

  return (
    <div className="mediaMain">
      {/* Header Section */}
      <div className="width90 maxWidth600 floatCenter textCenter">
        <p className="smallHead">{data.smallHead}</p>
        <p className="mainHeading">{data.mainHeading}</p>
        <p className="subPara">{data.subPara}</p>
      </div>

      {/* Media Cards */}
      <div className="width90 maxWidth1200 floatCenter">
        <div className="grid grid3 gap30">
          {Array.from({ length: 30 }, (_, i) => i + 1).map((i) => {
            const cardHeadingKey = `cardHeading${i}`;
            const imgKey = `img${i}old`;
            const dateKey = `date${i}`;
            const locationKey = `location${i}`;
            const btnLinkKey = `btnLink${i}`;
            const btnTextKey = `btnText${i}`;

            if (data[cardHeadingKey]) {
              return (
                <div
                  key={i}
                  className="mediaCard"
                  style={{
                    backgroundImage: `linear-gradient(180deg, #00000049 0%, #00000049 100%), url('${urls.assets}${data[imgKey]}')`,
                  }}
                >
                  <div className="dateSec">{data[dateKey]}</div>
                  <div className="cardInfoSec">
                    <div className="locationSec flex alignCenter gap10">
                      <i className="fa-solid fa-location-dot"></i>
                      <p>{data[locationKey]}</p>
                    </div>
                    <p className="cardHead">{data[cardHeadingKey]}</p>
                    <p className="readMore">
                      <a href={data[btnLinkKey]} target='_BLANK' title={data[btnTextKey]} >
                        {data[btnTextKey]} <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </p>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>

  )
}
