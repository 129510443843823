import React from 'react'
import Global from '../Global/Global';

export default function Gallerysection({data, list, id}) {
    const {urls} = Global();
    
  return (
    <div className="width100 galleryMain">
      <div className="width90 floatCenter textCenter" style={{ maxWidth: "900px" }}>
        <p className="smallText">{data.carGalSmallHeading}</p>
        <p className="bannerHeading">{data.carGalMainHeading}</p>
        <p className="tabSubPara" style={{ marginBottom: "50px" }}>
          {data.carGalSubPara}
        </p>
      </div>
      <div className="width100 maxWidth1500 floatCenter relative">
        <div className="width90 maxWidth1300 floatCenter">
          <div className="grid grid3 gap30">
            {Array.from({ length: 20 }, (_, i) => i + 1).map((i) => {
              const galleryImgKey = `galleryImg${i}old`;
              const galleryNameKey = `galleryName${i}`;

              if (data[galleryImgKey]) {
                return (
                  <div key={i} className="galleryCard">
                    <div className="galleryCardImg">

                      <a href="javascript:void(0)" data-fancybox="gallery" data-src={ urls.assets + data[galleryImgKey]}>
                        <img
                          className="width100"
                          src={ urls.assets + data[galleryImgKey]}
                          alt={data[galleryNameKey]}
                        />
                      </a>
                      
                    </div>
                      
                    <p className="galleryHeading textCenter">{data[galleryNameKey]}</p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
