import React from 'react'
import Productcard2 from './Productcard2';

export default function Pariservicesection({ data, list, id }) {
  return (
    <div className="serviceMain">
      {/* Header Section */}
      <div className="width90 maxWidth700 floatCenter textCenter">
        <span className="line"></span>
        <p className="abtHead">{data.smallHead}</p>
        <p className="abtSubHead">{data.mainHeading}</p>
        <p className="abtPara">{data.subPara}</p>
      </div>

      {/* Product Cards Section */}
      <div className="width90 maxWidth1300 floatCenter">
        <div className="grid grid3 gap40">
          {list.map((product, index) => (
            <Productcard2 key={index} product={product} />
          ))}
        </div>
      </div>
    </div>
  )
}
