import React from 'react'
import Productcard3 from './Productcard3'

export default function Cardsection1({data, list, id}) {
  return (
    <div className="cardMainSec owlTop">
      {/* Heading Section */}
      <div className="width90 maxWidth600 floatCenter textCenter">
        <p className="subHeading">{data.smallHead}</p>
        <p className="mainHeading" dangerouslySetInnerHTML={{ __html: data.popProHeading }} />
      </div>

      {/* Carousel Section */}
      <div className="width100 maxWidth1500 floatCenter relative">
        <div className="width90 maxWidth1300 floatCenter">
          <div
            className="owl-carousel owl-theme cardSecSlider"
            id={`id${id}`}
            data-autoplaytime={data.autoplaytime}
            data-dots={data.showdots}
            data-web={data.webCount}
            data-tab={data.tabCount}
            data-mob={data.mobCount}
          >
            {list.map((product, index) => (
              <div className="item" key={index}>
                <Productcard3 product={product} />
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Buttons */}
        {data.shownavs === "Yes" && (
          <div className="width100 navBtnMain flex alignCenter justifyBetween">
            <div className="navBtn left flex alignCenter justifyCenter">
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div className="navBtn right flex alignCenter justifyCenter">
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
