import React from 'react'
import Global from '../Global/Global';
export default function Paricta2({data, facilities, id}) {
    
    const {urls, empty} = Global();
  return (

    <div className="width100 ctaMain relative">
        <div className="grid grid2">

        {
            [...Array(2)].map(function(item, index){
                var i = index + 1
                if (!empty(data['heading'+i])) {
                    return(
                       
                        <div className="relative leftCtaMainSec" key={"head" + i}>
                            <div className={"textCenter " + (i == 1 ? 'leftCtaSec' : 'rightCtaSec')} style={{backgroundImage: "url('"+urls.assets + data['bgImg' + i + 'old'] +"')"}} >
                                <p className="ctaHead">{ data['heading' + i] }</p>
                                <p className="ctaPara">{ data['subPara' + i] }</p>
                                <div className="seeBtnSec">
                                    <a href={ data['btnLink' + i] } title={ data['btnText' + i] }>
                                        <button className="seeBtn">{ data['btnText' + i] }</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }

            <div className="absolute centerImg">
                <img className="width100" src={ urls.assets + data['centerImgold']} alt={data['centerImgAlt']} />
            </div>
        </div>
    </div>
  )
}
