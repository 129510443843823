import React, { useState } from 'react'
import Global from '../Global/Global';

export default function Sidepointersection({data, list, id}) {
    const {urls} = Global();
    

  return (
    <div
      className={`width100 ${
        data.secType === "Left Text" ? "aboutMain" : "aboutMain2"
      }`}
    >
      <div className="width90 maxWidth1300 floatCenter">
        <div className="grid grid2 gap20 alignCenter">
          {/* Conditional Rendering for "Left Text" */}
          {data.secType === "Left Text" && (
            <>
              <div className="aboutRight">
                <div className="imgSec width90 floatCenter">
                  <img
                    className="width100"
                    src={ urls.assets + data.aboutimageold}
                    alt={data.imagealt}
                  />
                </div>
              </div>
              <div className="aboutLeft order">
                <p className="mainHeading">{data.aboutheading}</p>
                <p className="mainSubPara">{data.aboutpara}</p>
              </div>
            </>
          )}

          {/* Conditional Rendering for "Right Text" */}
          {data.secType === "Right Text" && (
            <>
              <div className="aboutLeft order">
                <p className="mainHeading">{data.aboutheading}</p>
                <p className="mainSubPara">{data.aboutpara}</p>
              </div>
              <div className="aboutRight">
                <div className="imgSec width90 floatCenter">
                  <img
                    className="width100"
                    src={urls.assets + data.aboutimageold}
                    alt={data.imagealt}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
