import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import { useParams } from "react-router-dom";
import Menu from '../Menu/Menu';
import Global from '../Global/Global';

import Loadingstatic from '../Global/Loadingstatic';

export default function Sponser() {
    const { slug } = useParams();
    const {callApi, addLessFiles, addScript, urls, empty} = Global();
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(0)
    const [logo, setLogo] = useState("")
    const [noofkali, setNoOfKali] = useState(1)
    const [forYear, setForYear] = useState(1)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [citizen, setCitizen] = useState("")

    const [product, setProduct] = useState(null)

    const getProduct = async () => {
        const resp = await callApi({"slug": slug}, "getProductReact", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            if(empty(resp.redirect)){
                setProduct(resp.product)
                setLogo(resp.logo)
            }
        }
        setLoading(false)
    }
    const donationDone = async (response) => {
        
        window.callAlert("Loading Please Wait...", "infoAlert", 1000000);

        var payid = response.razorpay_payment_id
        const resp = await callApi({"name": name, "email": email, "contact": contact, "citizen": citizen, "payid": payid, "postid": product.id, "paid": amount, "noofkali": noofkali, "forYear": forYear, "type": "Sponsership"}, "makeDonation", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            window.callAlert("Payment Successful Redirecting Please Wait...", "infoSuccess");
            window.location.href="/success/" + resp.donationid
        }
    }

    const callRazorPay = () => {
        window.callAlert("Loading Please Wait...", "infoAlert", 1000000);
        const options = {
            key: "rzp_live_MBhGSK6P04PORE", // Replace with your Razorpay Key
            amount: amount * 100, // Amount in paisa (e.g., ₹500 = 50000)
            currency: "INR",
            name: "Pari Foundation",
            description: product.pname,
            handler: function (response) {
                donationDone(response)
            //   console.log(response);
              // Handle success here
            },
            prefill: {
              name: name,
              email: email,
              contact: contact,
            },
            theme: {
              color: "#3399cc",
            },
          };
          
          const razorpay = new window.Razorpay(options);
          razorpay.open();
            
          // Attach a payment failure handler
            razorpay.on("payment.failed", function (response) {
                window.$(".alerts").fadeOut();
            });
            razorpay.on("payment.canceled", function (response) {
                window.$(".alerts").fadeOut();
            });
    }
    
    const makeDonation = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneRegex = /^\+?[0-9]{1,4}?[0-9]{9}$/;

        if(amount < 1){
            window.callAlert("Amount Cannot Be Less Than 1", "warningAlert")
        }else if(name === ""){
            window.callAlert("Name Cannot be Null!!!", "warningAlert")
        }else if(email === ""){
            window.callAlert("Email Cannot be Null!!!", "warningAlert")
        }else if(!emailRegex.test(email)){
            window.callAlert("Please Enter Valid EMail Address!!!", "warningAlert")
        }else if(contact === ""){
            window.callAlert("Contact Cannot be Null!!!", "warningAlert")
        }else if(!phoneRegex.test(contact)){
            window.callAlert("Invalid Contact No!!!", "warningAlert")
        }else if(citizen === ""){
            window.callAlert("Please Select Citizenship First!!!", "warningAlert")
        }else{
            callRazorPay()
        }
        
    }

    const calculateAmount = (val, type) => {
        var noka = noofkali
        var year = forYear
        if(type === "Nokali"){
            setNoOfKali(val)
            noka = val
        }else if(type === "Year"){
            setForYear(val)
            year = val
        }

        var sum = (parseInt(product.formdata.sponsershipcost)) * noka * year;
        setAmount(sum)
    }
    const selectcss = {
        outline: 'none',
        border: 'none',
        backgroundColor: 'var(--themeBg1)',
        marginTop: '8px',
        width: '100%', // or 'webkit-fill-available' depending on browser support
        padding: '15px',
        borderRadius: '5px'
      };

    useEffect(() => {
        getProduct()
        
        
        const less = addLessFiles( urls.assets + "assets/less/campaign-donation-page.less");
        const script = addScript( urls.assets + 'assets/js/pages/campaign-donation-page.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };

    }, [])
  return (
    <>
        <Menu />
        <div className='p_22_s190'>
        {
            loading &&
            <div style={{padding:"100px 0"}} >
                <Loadingstatic />
            </div>
        }
        {
            !loading &&
            product !== null &&
            <div className="sponMain">
                <div className="width90 maxWidth1100 floatCenter">
                    <div className="grid sponGrid gap30">
                    {/* Left Section */}
                    <div className="leftMain">
                        <p className="mainHeading">Let's Get Started</p>
                        <div className='ck-content' dangerouslySetInnerHTML={{__html: product.descriptions[2].p_data}}></div>
                        {/* Amount Section */}
                        
                        <div className="amountSec flex alignCenter justifyBetween gap20">
                            <p className="amtHead">Annual Sponsorship Cost</p>
                            <p className="amtMain">
                                <i className="fa-solid fa-indian-rupee-sign"></i> {product.formdata.sponsershipcost}
                            </p>
                        </div>
                        

                        <p className="mainHeading" style={{fontSize:"20px"}}>No of Pari(s)</p>
                        <div className="multiAmt grid cusGrid alignCenter gap10" style={{marginTop:"15px", alignItems:"flex-end"}}>
                            <div className="amtSec grid grid5 alignCenter gap10">
                                {
                                    [...Array(5)].map((item, index) => {
                                        return(
                                            <div className={"amt " + ( noofkali === (index + 1) ? 'active' : '' )} key={"nokali" + index} onClick={(e) => calculateAmount((index + 1), "Nokali")}>
                                                 {index + 1}
                                            </div>
                                        )
                                    })
                                }
                                
                            </div>
                            <div className="cusAmtSec ">
                                <p className='center-text' style={{paddingBottom:"5px"}}>
                                    <small><strong>Custom No</strong></small>
                                </p>
                                <div className="amt">
                                    <input type="number" placeholder="Custom Amount" value={noofkali} className='center-text' onChange={(e) => calculateAmount(e.target.value, "Nokali")} />
                                </div>
                            </div>
                        </div>

                        

                        <p className="mainHeading" style={{fontSize:"20px"}}>No of Year(s)</p>
                        <div className="multiAmt grid cusGrid alignCenter gap10" style={{marginTop:"15px", alignItems:"flex-end"}}>
                            <div className="amtSec grid grid5 alignCenter gap10">
                                {
                                    [...Array(5)].map((item, index) => {
                                        return(
                                            <div className={"amt " + ( forYear === (index + 1) ? 'active' : '' )} key={"year" + index} onClick={(e) => calculateAmount((index + 1), "Year")}>
                                                 {index + 1}
                                            </div>
                                        )
                                    })
                                }
                                
                            </div>
                            <div className="cusAmtSec">
                                <p className='center-text' style={{paddingBottom:"5px"}}>
                                    <small><strong>Custom Year(s)</strong></small>
                                </p>
                                <div className="amt">
                                    <input type="number" placeholder="Custom Amount" value={forYear} className='center-text' onChange={(e) => calculateAmount(e.target.value, "Year")} />
                                </div>
                            </div>
                        </div>

                        {/* Personal Info Section */}
                        <p className="mainHeading">Personal Info</p>
                        <div className="fullNameSec grid grid2 gap20">
                            <div className="inputSec">
                                <label>
                                Your Name <span style={{ color: "red" }}>*</span>
                                </label>
                                <br />
                                <input type="text" placeholder="e.g John Doe" name='name' value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="inputSec">
                                <label>
                                Your Citizenship<span style={{ color: "red" }}>*</span>
                                </label>
                                <br />
                                    <select  style={selectcss} value={citizen} onChange={(e) => setCitizen(e.target.value)}>
                                        <option value="" >Select Country</option>
                                        <option value="AF-AFGHANISTAN">AFGHANISTAN</option>
                                        <option value="AX-ALAND ISLANDS">ALAND ISLANDS</option>
                                        <option value="AL-ALBANIA">ALBANIA</option>
                                        <option value="DZ-ALGERIA">ALGERIA</option>
                                        <option value="AS-AMERICAN SAMOA">AMERICAN SAMOA</option>
                                        <option value="AD-ANDORRA">ANDORRA</option>
                                        <option value="AO-ANGOLA">ANGOLA</option>
                                        <option value="AI-ANGUILLA">ANGUILLA</option>
                                        <option value="AQ-ANTARCTICA">ANTARCTICA</option>
                                        <option value="AG-ANTIGUA AND BARBUDA">ANTIGUA AND BARBUDA</option>
                                        <option value="AR-ARGENTINA">ARGENTINA</option>
                                        <option value="AM-ARMENIA">ARMENIA</option>
                                        <option value="AW-ARUBA">ARUBA</option>
                                        <option value="AU-AUSTRALIA">AUSTRALIA</option>
                                        <option value="AT-AUSTRIA">AUSTRIA</option>
                                        <option value="AZ-AZERBAIJAN">AZERBAIJAN</option>
                                        <option value="BS-BAHAMAS">BAHAMAS</option>
                                        <option value="BH-BAHRAIN">BAHRAIN</option>
                                        <option value="BD-BANGLADESH">BANGLADESH</option>
                                        <option value="BB-BARBADOS">BARBADOS</option>
                                        <option value="BY-BELARUS">BELARUS</option>
                                        <option value="BE-BELGIUM">BELGIUM</option>
                                        <option value="BZ-BELIZE">BELIZE</option>
                                        <option value="BJ-BENIN">BENIN</option>
                                        <option value="BM-BERMUDA">BERMUDA</option>
                                        <option value="BT-BHUTAN">BHUTAN</option>
                                        <option value="BO-BOLIVIA">BOLIVIA</option>
                                        <option value="BA-BOSNIA AND HERZEGOVINA">BOSNIA AND HERZEGOVINA</option>
                                        <option value="BW-BOTSWANA">BOTSWANA</option>
                                        <option value="BR-BRAZIL">BRAZIL</option>
                                        <option value="BN-BRUNEI">BRUNEI</option>
                                        <option value="BG-BULGARIA">BULGARIA</option>
                                        <option value="BF-BURKINA FASO">BURKINA FASO</option>
                                        <option value="BI-BURUNDI">BURUNDI</option>
                                        <option value="KH-CAMBODIA">CAMBODIA</option>
                                        <option value="CM-CAMEROON">CAMEROON</option>
                                        <option value="CA-CANADA">CANADA</option>
                                        <option value="CV-CAPE VERDE">CAPE VERDE</option>
                                        <option value="CF-CENTRAL AFRICAN REPUBLIC">CENTRAL AFRICAN REPUBLIC</option>
                                        <option value="TD-CHAD">CHAD</option>
                                        <option value="CL-CHILE">CHILE</option>
                                        <option value="CN-CHINA">CHINA</option>
                                        <option value="CO-COLOMBIA">COLOMBIA</option>
                                        <option value="KM-COMOROS">COMOROS</option>
                                        <option value="CG-CONGO">CONGO</option>
                                        <option value="CR-COSTA RICA">COSTA RICA</option>
                                        <option value="CI-COTE D'IVOIRE">COTE D'IVOIRE</option>
                                        <option value="HR-CROATIA">CROATIA</option>
                                        <option value="CU-CUBA">CUBA</option>
                                        <option value="CY-CYPRUS">CYPRUS</option>
                                        <option value="CZ-CZECH REPUBLIC">CZECH REPUBLIC</option>
                                        <option value="DK-DENMARK">DENMARK</option>
                                        <option value="DJ-DJIBOUTI">DJIBOUTI</option>
                                        <option value="DM-DOMINICA">DOMINICA</option>
                                        <option value="DO-DOMINICAN REPUBLIC">DOMINICAN REPUBLIC</option>
                                        <option value="EC-ECUADOR">ECUADOR</option>
                                        <option value="EG-EGYPT">EGYPT</option>
                                        <option value="SV-EL SALVADOR">EL SALVADOR</option>
                                        <option value="GQ-EQUATORIAL GUINEA">EQUATORIAL GUINEA</option>
                                        <option value="ER-ERITREA">ERITREA</option>
                                        <option value="EE-ESTONIA">ESTONIA</option>
                                        <option value="ET-ETHIOPIA">ETHIOPIA</option>
                                        <option value="FJ-FIJI">FIJI</option>
                                        <option value="FI-FINLAND">FINLAND</option>
                                        <option value="FR-FRANCE">FRANCE</option>
                                        <option value="GA-GABON">GABON</option>
                                        <option value="GM-GAMBIA">GAMBIA</option>
                                        <option value="GE-GEORGIA">GEORGIA</option>
                                        <option value="DE-GERMANY">GERMANY</option>
                                        <option value="GH-GHANA">GHANA</option>
                                        <option value="GR-GREECE">GREECE</option>
                                        <option value="GD-GRENADA">GRENADA</option>
                                        <option value="GT-GUATEMALA">GUATEMALA</option>
                                        <option value="GN-GUINEA">GUINEA</option>
                                        <option value="GY-GUYANA">GUYANA</option>
                                        <option value="HT-HAITI">HAITI</option>
                                        <option value="HN-HONDURAS">HONDURAS</option>
                                        <option value="HU-HUNGARY">HUNGARY</option>
                                        <option value="IS-ICELAND">ICELAND</option>
                                        <option value="IN-INDIA">INDIA</option>
                                        <option value="ID-INDONESIA">INDONESIA</option>
                                        <option value="IR-IRAN">IRAN</option>
                                        <option value="IQ-IRAQ">IRAQ</option>
                                        <option value="IE-IRELAND">IRELAND</option>
                                        <option value="IL-ISRAEL">ISRAEL</option>
                                        <option value="IT-ITALY">ITALY</option>
                                        <option value="JM-JAMAICA">JAMAICA</option>
                                        <option value="JP-JAPAN">JAPAN</option>
                                        <option value="JO-JORDAN">JORDAN</option>
                                        <option value="KZ-KAZAKHSTAN">KAZAKHSTAN</option>
                                        <option value="KE-KENYA">KENYA</option>
                                        <option value="KI-KIRIBATI">KIRIBATI</option>
                                        <option value="KR-SOUTH KOREA">SOUTH KOREA</option>
                                        <option value="KW-KUWAIT">KUWAIT</option>
                                        <option value="KG-KYRGYZSTAN">KYRGYZSTAN</option>
                                        <option value="LA-LAOS">LAOS</option>
                                        <option value="LV-LATVIA">LATVIA</option>
                                        <option value="LB-LEBANON">LEBANON</option>
                                        <option value="LR-LIBERIA">LIBERIA</option>
                                        <option value="LY-LIBYA">LIBYA</option>
                                        <option value="LT-LITHUANIA">LITHUANIA</option>
                                        <option value="LU-LUXEMBOURG">LUXEMBOURG</option>
                                        <option value="MG-MADAGASCAR">MADAGASCAR</option>
                                        <option value="MW-MALAWI">MALAWI</option>
                                        <option value="MY-MALAYSIA">MALAYSIA</option>
                                        <option value="MV-MALDIVES">MALDIVES</option>
                                        <option value="ML-MALI">MALI</option>
                                        <option value="MT-MALTA">MALTA</option>
                                        <option value="MX-MEXICO">MEXICO</option>
                                        <option value="MD-MOLDOVA">MOLDOVA</option>
                                        <option value="MC-MONACO">MONACO</option>
                                        <option value="MN-MONGOLIA">MONGOLIA</option>
                                        <option value="ME-MONTENEGRO">MONTENEGRO</option>
                                        <option value="MA-MOROCCO">MOROCCO</option>
                                        <option value="MZ-MOZAMBIQUE">MOZAMBIQUE</option>
                                        <option value="MM-MYANMAR">MYANMAR</option>
                                        <option value="NA-NAMIBIA">NAMIBIA</option>
                                        <option value="NR-NAURU">NAURU</option>
                                        <option value="NP-NEPAL">NEPAL</option>
                                        <option value="NL-NETHERLANDS">NETHERLANDS</option>
                                        <option value="NC-NEW CALEDONIA">NEW CALEDONIA</option>
                                        <option value="NZ-NEW ZEALAND">NEW ZEALAND</option>
                                        <option value="NI-NICARAGUA">NICARAGUA</option>
                                        <option value="NE-NIGER">NIGER</option>
                                        <option value="NG-NIGERIA">NIGERIA</option>
                                        <option value="NU-NIUE">NIUE</option>
                                        <option value="NF-NORFOLK ISLAND">NORFOLK ISLAND</option>
                                        <option value="MP-NORTHERN MARIANA ISLANDS">NORTHERN MARIANA ISLANDS</option>
                                        <option value="NO-NORWAY">NORWAY</option>
                                        <option value="OM-OMAN">OMAN</option>
                                        <option value="PK-PAKISTAN">PAKISTAN</option>
                                        <option value="PW-PALAU">PALAU</option>
                                        <option value="PA-PANAMA">PANAMA</option>
                                        <option value="PG-PAPUA NEW GUINEA">PAPUA NEW GUINEA</option>
                                        <option value="PY-PARAGUAY">PARAGUAY</option>
                                        <option value="PE-PERU">PERU</option>
                                        <option value="PH-PHILIPPINES">PHILIPPINES</option>
                                        <option value="PL-POLAND">POLAND</option>
                                        <option value="PT-PORTUGAL">PORTUGAL</option>
                                        <option value="PR-PUERTO RICO">PUERTO RICO</option>
                                        <option value="QA-QATAR">QATAR</option>
                                        <option value="RE-REUNION">REUNION</option>
                                        <option value="RO-ROMANIA">ROMANIA</option>
                                        <option value="RU-RUSSIA">RUSSIA</option>
                                        <option value="RW-RWANDA">RWANDA</option>
                                        <option value="SH-SAINT HELENA">SAINT HELENA</option>
                                        <option value="KN-SAINT KITTS AND NEVIS">SAINT KITTS AND NEVIS</option>
                                        <option value="LC-SAINT LUCIA">SAINT LUCIA</option>
                                        <option value="PM-SAINT PIERRE AND MIQUELON">SAINT PIERRE AND MIQUELON</option>
                                        <option value="VC-SAINT VINCENT AND THE GRENADINES">SAINT VINCENT AND THE GRENADINES</option>
                                        <option value="WS-SAMOA">SAMOA</option>
                                        <option value="SM-SAN MARINO">SAN MARINO</option>
                                        <option value="ST-SAO TOME AND PRINCIPE">SAO TOME AND PRINCIPE</option>
                                        <option value="SA-SAUDI ARABIA">SAUDI ARABIA</option>
                                        <option value="SN-SENEGAL">SENEGAL</option>
                                        <option value="RS-SERBIA">SERBIA</option>
                                        <option value="SC-SEYCHELLES">SEYCHELLES</option>
                                        <option value="SL-SIERRA LEONE">SIERRA LEONE</option>
                                        <option value="SG-SINGAPORE">SINGAPORE</option>
                                        <option value="SX-SINT MAARTEN">SINT MAARTEN</option>
                                        <option value="SK-SLOVAKIA">SLOVAKIA</option>
                                        <option value="SI-SLOVENIA">SLOVENIA</option>
                                        <option value="SB-SOLOMON ISLANDS">SOLOMON ISLANDS</option>
                                        <option value="SO-SOMALIA">SOMALIA</option>
                                        <option value="ZA-SOUTH AFRICA">SOUTH AFRICA</option>
                                        <option value="GS-SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS">SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS</option>
                                        <option value="SS-SOUTH SUDAN">SOUTH SUDAN</option>
                                        <option value="ES-SPAIN">SPAIN</option>
                                        <option value="LK-SRI LANKA">SRI LANKA</option>
                                        <option value="SD-SUDAN">SUDAN</option>
                                        <option value="SR-SURINAME">SURINAME</option>
                                        <option value="SJ-SVALBARD AND JAN MAYEN">SVALBARD AND JAN MAYEN</option>
                                        <option value="SE-SWEDEN">SWEDEN</option>
                                        <option value="CH-SWITZERLAND">SWITZERLAND</option>
                                        <option value="SY-SYRIA">SYRIA</option>
                                        <option value="TW-TAIWAN">TAIWAN</option>
                                        <option value="TJ-TAJIKISTAN">TAJIKISTAN</option>
                                        <option value="TZ-TANZANIA">TANZANIA</option>
                                        <option value="TH-THAILAND">THAILAND</option>
                                        <option value="TL-TIMOR-LESTE">TIMOR-LESTE</option>
                                        <option value="TG-TOGO">TOGO</option>
                                        <option value="TK-TOKELAU">TOKELAU</option>
                                        <option value="TO-TONGA">TONGA</option>
                                        <option value="TT-TRINIDAD AND TOBAGO">TRINIDAD AND TOBAGO</option>
                                        <option value="TN-TUNISIA">TUNISIA</option>
                                        <option value="TR-TURKEY">TURKEY</option>
                                        <option value="TM-TURKMENISTAN">TURKMENISTAN</option>
                                        <option value="TC-TURKS AND CAICOS ISLANDS">TURKS AND CAICOS ISLANDS</option>
                                        <option value="TV-TUVALU">TUVALU</option>
                                        <option value="UG-UGANDA">UGANDA</option>
                                        <option value="UA-UKRAINE">UKRAINE</option>
                                        <option value="AE-UNITED ARAB EMIRATES">UNITED ARAB EMIRATES</option>
                                        <option value="GB-UNITED KINGDOM">UNITED KINGDOM</option>
                                        <option value="US-UNITED STATES">UNITED STATES</option>
                                        <option value="UY-URUGUAY">URUGUAY</option>
                                        <option value="UZ-UZBEKISTAN">UZBEKISTAN</option>
                                        <option value="VU-VANUATU">VANUATU</option>
                                        <option value="VE-VENEZUELA">VENEZUELA</option>
                                        <option value="VN-VIETNAM">VIETNAM</option>
                                        <option value="VG-VIRGIN ISLANDS">VIRGIN ISLANDS</option>
                                        <option value="WF-WALLIS AND FUTUNA">WALLIS AND FUTUNA</option>
                                        <option value="EH-WESTERN SAHARA">WESTERN SAHARA</option>
                                        <option value="YE-YEMEN">YEMEN</option>
                                        <option value="ZM-ZAMBIA">ZAMBIA</option>
                                        <option value="ZW-ZIMBABWE">ZIMBABWE</option>

                                    </select>
                            </div>
                        </div>
                        <div className="fullNameSec grid grid2 gap20">
                            <div className="inputSec">
                                <label>
                                Contact No<span style={{ color: "red" }}>*</span>
                                </label>
                                <br />
                                <input type="number" placeholder="e.g 919711xxxxxx"  name='contact' value={contact} onChange={(e) => setContact(e.target.value)} />
                            </div>
                            <div className="inputSec">
                                <label>
                                    Email <span style={{ color: "red" }}>*</span>
                                </label>
                                <br />
                                <input type="email" placeholder="e.g info@example" name='email'  value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <p className="totalAmt">
                        Donation Total: <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        { amount }
                        </p>

                        {/* Donate Button */}
                        {
                            amount > 0 && (product.raisedper < 100) && 
                            <button className="donateBtnSec flex alignCenter" onClick={() => makeDonation()}>
                                <span>
                                    <i className="fa-solid fa-angles-right"></i>
                                </span>
                                &nbsp;&nbsp; Donate Now
                            </button>
                        }
                        {
                            product.raisedper >= 100 &&
                            <div className='ck-content'>
                                <p style={{color:"red", marginTop:"50px", textAlign:"center"}}>
                                    <strong>
                                        Thank you for your generosity! The donation goal has already been reached, and we are no longer accepting additional contributions for this cause. We deeply appreciate your willingness to support and encourage you to stay tuned for future opportunities to make a difference.
                                    </strong>
                                </p>
                            </div>
                        }
                    </div>

                    {/* Right Section */}
                    
                    <div className="rightMain relative">
                            {/* Card Main Section */}
                            <div className="cardMain">
                                <div className="cardImgSec relative">
                                <img
                                    className="width100"
                                    src={urls.assets + product.defaultImage.image}
                                    alt="Event"
                                />
                                {
                                    !empty(product.goal) &&
                                    <p className="percent">{product.raisedper}%</p>
                                }
                                </div>
                                <div className="cardTextSec">
                                    
                                {
                                    !empty(product.goal) &&
                                        <div className="progressBar">
                                            <div className="outerDiv">
                                            <div className="innerDiv" style={{width:product.raisedper+'%'}}></div>
                                            </div>
                                        </div>
                                }
                                {
                                    !empty(product.goal) &&
                                    <div className="cardInfoInner">
                                        <div className="grid grid2 gap10">
                                        <div className="innerSec">
                                            <p className="greyPara">Raised</p>
                                            <p className="donatePara">₹{product.raised}</p>
                                        </div>
                                        <div className="innerSec">
                                            <p className="greyPara">Goal</p>
                                            <p className="donatePara">₹{product.goal}</p>
                                        </div>
                                        </div>
                                    </div>
                                }
                                <p className="cardHead">{product.pname}</p>
                                <p className="cardPara">
                                    {product.formdata.card_description}
                                </p>

                                <div className="cardInfoInner padding10" style={{padding:"10px 0"}}>
                                    <div className="grid grid2 gap10 textCenter">
                                    <div className="innerSec">
                                        <p className="greyPara">Start Date</p>
                                        <p className="donatePara">{product.start_date_string}</p>
                                    </div>
                                    <div className="innerSec">
                                        <p className="greyPara">End Date</p>
                                        <p className="donatePara">{ empty(product.end_date_string) ? '-' : product.end_date_string}</p>
                                    </div>
                                    </div>
                                </div>
                                
                                </div>
                            </div>

                            {
                                !empty(product.formdata.helplineno) &&
                                <div className="orgMain">
                                    {/* <p style={{fontSize:"20px", paddingBottom:"10px"}} className='center-text' >HELPLINE NO</p> */}
                                    {
                                        !empty(logo) &&
                                        <div className="logoSec textCenter">
                                            <img
                                                className="width80"
                                                src={urls.assets + logo}
                                                alt="Pari Foundation"
                                            />
                                        </div>
                                    }
                                    
                                    {
                                        product.formdata.helplineno.split(",").map((item, index) => {
                                                return(
                                                    <p className="noSec" key={"help"+index} style={{paddingTop:"10px"}}>
                                                        <a href={ 'tel:' + item} className="donateBtnSec flex alignCenter">
                                                            <span>
                                                            <i className="fa-solid fa-phone"></i>
                                                            </span>
                                                            &nbsp;&nbsp;{item}
                                                        </a>
                                                    </p>
                                                )
                                            })
                                        }
                                    
                                    
                                </div>
                            }

                        </div>
                    </div>
                </div>
                </div>
            }
        </div>
        <Footer />
    </>
  )
}
