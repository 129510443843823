import React from 'react'
import Global from '../Global/Global';

export default function Videosection2({ data, list, id }) {
    
    const {urls, empty} = Global();
  return (
    <div className="vidSec">
      <div className="vidInnerSec">
        <div className="grid grid2 alignCenter">
          {/* Left Section */}
          <div className="leftSec">
            <div className="width90 maxWidth700 floatRight">
              <p className="vidHead">{data.smallHead}</p>
              <p className="vidSubHead">{data.mainHeading}</p>
              <p className="vidPara">{data.subPara}</p>
              {/* Product Cards */}
              {list.map((product, index) => (
                <div className="cardMain" key={index}>
                  <div className="grid cardGrid gap30 alignCenter">
                    {/* Card Image Section */}
                    <div className="cardImgSec relative">
                      <img
                        className="width100"
                        src={urls.assets + product.image}
                        alt={product.name}
                      />
                      {
                        !empty(product.goal) &&
                        <p className="percent"> {product.raisedper}% </p>
                      }
                    </div>
                    {/* Card Text Section */}
                    <div className="cardTextSec">
                      <p className="cardHead">{product.name}</p>
                      <p className="cardPara">
                      {product.card_description}
                      </p>
                      
                      {
                        !empty(product.goal) &&
                        <div className="progressBar">
                          <div className="outerDiv">
                            <div className="innerDiv" style={{width:product.raisedper+"%"}}></div>
                          </div>
                        </div>
                      }
                      {/* Donation Info */}
                      {
                        !empty(product.goal) &&
                      <div className="cardInfoInner">
                        <div className="grid grid2 gap10">
                          <div className="innerSec">
                            <p className="greyPara">Raised</p>
                            <p className="donatePara">₹{product.raised}</p>
                          </div>
                          <div className="innerSec">
                            <p className="greyPara">Goal</p>
                            <p className="donatePara">₹{product.goal}</p>
                          </div>
                        </div>
                      </div>
                      }
                      {/* Donate Button */}
                      <div className="joinBtnSec">
                        <a href={product.url} title="Donate Now">
                          <button className="joinBtn">Donate Now</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Section */}
          <div
            className="rightSec"
            style={{
              backgroundImage: `url(${urls.assets + data.bgImgold})`,
            }}
          >
            <p className="watchPara">{data.rightText}</p>
            <div className="vidPlayBtn">
              <a href={data.btnLink} title="Play Now">
                <div className="vidBtn floatCenter flex alignCenter justifyCenter">
                  <div className="blinkDiv"></div>
                  <i className="fa-solid fa-play"></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
