import React, { useEffect } from 'react'

export default function Ticker({social, media}) {

  const callTickyFunc = () => {
    if(typeof window.callTicker === "function"){
      window.callTicker()
    }else{
      callTickyFunc()
    }
  }
  useEffect(() => {
    setTimeout(function(){
      callTickyFunc()
    }, 1000)
  }, [])
  return (
    <div className="myMenuTopBar">
      <div className="grid grid3 maxWidth1500 float-center width96 alignCenter">
        {/* Contact and Email Section */}
        <div>
          <ul className="myMenuIconsArea">
            {social.contact1 && (
              <li>
                <a
                  href={`tel:${social.contact1}`}
                  title={`Call us On ${social.contact1}`}
                >
                  <i className="fas fa-phone-volume"></i> {social.contact1}
                </a>
              </li>
            )}
            {social.email1 && (
              <li>
                <a
                  href={`mailto:${social.email1}`}
                  title={`Mail us On ${social.email1}`}
                >
                  <i className="fas fa-envelope"></i> {social.email1}
                </a>
              </li>
            )}
          </ul>
        </div>

        {/* Ticker Section */}
        <div className="midTicker">
          <div className="tickerNav" data-direction="left">
            <i className="fas fa-chevron-left"></i>
          </div>
          <div style={{ overflow: "hidden" }}>
            <div className="owl-carousel owl-theme tickerMain">
              {[...Array(10)].map((_, i) => {
                const text = social[`slider_text${i + 1}`];
                const link = social[`slider_Link${i + 1}`];
                return (
                  text && (
                    <div className="item center-text" key={i}>
                      <a href={link} title={text}>
                        <span>{text}</span>
                      </a>
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <div className="tickerNav" data-direction="right">
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>

        {/* Social Links Section */}
        <div className="text-right">
          <ul className="myMenuIconsArea">
            {
              media.length > 0 &&
              media.map((item, index) => {
                return(
                  <li key={index}>
                    <a href={item.link} title={item.type} className="cicleIcon">
                      <i className={`fas ${item.icon}`}></i>
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  )
}
