
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Campaign from './components/Page/Campaign';
import Donation from './components/Page/Donation';
import Page from './components/Page/Page';
import Sponser from './components/Page/Sponser';
import Blogs from './components/Page/Blogs';
import Blog from './components/Page/Blog';
import Login from './components/Page/Login';
import Signup from './components/Page/Signup';
import Dashboard from './components/Page/Dashboard';
import Verifyotp from './components/Page/Verifyotp';
import Forgot from './components/Page/Forgot';
import Profile from './components/Page/Profile';
import Donations from './components/Page/Donations';
import Ongoing from './components/Page/Ongoing';
import Timebased from './components/Page/Timebased';
import Upcommingevent from './components/Page/Upcommingevent';
import Pastevent from './components/Page/Pastevent';
import Policy from './components/Page/Policy';


function App() {
  return (
    
    <Routes basename={process.env.PUBLIC_URL}>
        <Route path="/" element={<Page pageid={'homepage'} count={17} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/c/:slug" element={<Campaign />} />
        <Route path="/donate/:slug" element={<Donation />} />
        <Route path="/sponser/:slug" element={<Sponser />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:slug" element={<Blog />} />
        
        <Route path="/success/:donationid" element={<Page pageid={'paidpage'} count={2} />} />

        <Route path="/blog/enquiry/:enqid" element={<Page pageid={'bloogenq'} count={2} />} />
        <Route path="/newsletter/:enqid" element={<Page pageid={'newsenq'} count={2} />} />
        <Route path="/policy/:enqid" element={<Page pageid={'policyenq'} count={2} />} />
        <Route path="/volunteerenq/:enqid" element={<Page pageid={'volunteerenq'} count={2} />} />
        
        
        
        <Route path="/about-us" element={<Page pageid={'about-us'} count={6} />} />
        <Route path="/our-partners" element={<Page pageid={'partners'} count={2} />} />
        <Route path="/certificates" element={<Page pageid={'certificates'} count={6} />} />
        <Route path="/volunteer" element={<Page pageid={'volunteer'} count={2} />} />
        <Route path="/helpline" element={<Page pageid={'helpline'} count={3} />} />
        <Route path="/photo-gallery" element={<Page pageid={'image-gallery'} count={3} />} />
        <Route path="/key-programmes" element={<Page pageid={'key-programmes'} count={3} />} />

        <Route path="/policies/:slug" element={<Policy />} />

        <Route path="/policies" element={<Page pageid={'policies'} count={3} />} />
        <Route path="/print-media" element={<Page pageid={'print-media'} count={3} />} />
        <Route path="/sos-pari" element={<Page pageid={'sos-pari'} count={6} />} />
        <Route path="/templates" element={<Page pageid={'templates'} count={6} />} />
        <Route path="/video-gallery" element={<Page pageid={'video-gallery'} count={3} />} />
        <Route path="/yogita-bhayana" element={<Page pageid={'yogita-bhayana'} count={3} />} />


        <Route path="/user/dashboard" element={<Dashboard />} />
        <Route path="/user/verifyotp" element={<Verifyotp />} />
        <Route path="/user/profile" element={<Profile />} />
        <Route path="/user/donations" element={<Donations />} />
        <Route path="/contact-us" element={<Page pageid={'contact'} count={2} />} />

        <Route path="/ongoing-projects" element={<Ongoing />} />
        <Route path="/time-base-projects" element={<Timebased />} />
        <Route path="/upcoming-projects" element={<Upcommingevent />} />
        <Route path="/past-projects" element={<Pastevent />} />
        
        
      </Routes>
      
  );
}

export default App;
