import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import './Login.css'
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import Sidebar from './Sidebar';
import Loadingstatic from '../Global/Loadingstatic';


export default function Donations() {
    const {callApi, empty, urls, getUser, addCss} = Global();
    const [user, setUser] = useState("")
    const [loading, setLoading] = useState(true)
    const findUser = async () => {
        const user = await getUser()
        setUser(user)
        window.feather.replace()
        myDonations(user)
    }
    const [donations, setDonations] = useState([])
    const [total, setTotal] = useState(0)

    const myDonations = async (user) => {
        const resp = await callApi({id:user.id}, "myDonationsReact", "POST")
        if(resp.status === "OK"){
            setDonations(resp.donations.data)
            setTotal(resp.donations.total)
            setLoading(false)
        }
    }
    
    useEffect(() => {
        if(empty(localStorage.getItem('userid'))){
            window.location.href = "/login"
        }
        findUser()

        const less = addCss( urls.assets + "assets/css/userdashboard.css");
        return () => {
            document.head.removeChild(less);
        };
        
        
    }, [])

  return (
    <>
        <Menu />
            <div className="userDashboardV2">
                {
                    user !== "" &&
                    <div className="width90  maxWidth1500 float-center userDashboardV2Grid">
                        <Sidebar active="Donations" userName={user.name}  />
                        <div className="userdashboardV2Right">
                            
                            <div className="rightSIdeBlocks">
                                <p class="blocksHeading" style={{padding:"10px", marginBottom:"10px"}}><strong>My Donations</strong> 
                                    &nbsp;
                                    {
                                        loading ?
                                        <span style={{color:"red"}}>Loading...</span>
                                        :
                                        <span ><strong>({total})</strong></span>
                                    }
                                </p>
                                <table className='myTableV2' border={1}>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Donated For</th>
                                            <th>Donated Amount</th>
                                            <th>Donation ID</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            donations.length > 0 &&
                                            donations.map((item, index) => {
                                                
                                                return(
                                                    <tr key={"done" + index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>
                                                            {item.pname}
                                                        </td>
                                                        <td>
                                                            {item.paid}
                                                        </td>
                                                        <td>
                                                            {item.donationid}
                                                        </td>
                                                        <td>
                                                            {item.date}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                {
                    user === "" &&
                    <div style={{height:"50vh"}}>
                        <Loadingstatic />
                    </div>
                }
            </div>
            
        <Footer />
    </>
  )
}
