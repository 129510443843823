import React from 'react'
import Blogcard from './Blogcard';

export default function Blogsection({data, list, id}) {
  return (
    <div className="proMainSec owlTop">
      <div className="width90 maxWidth600 floatCenter textCenter">
        <p className="mainHeading">{data.popProHeading}</p>
        <p className="subPara">{data.popProSubPara}</p>
      </div>

      <div className="relative">
        <div className="width90 maxWidth1500 floatCenter">
        <div className="owl-carousel owl-theme blogSlider" id={"id" + id} data-autoPlayTime={ data['autoplaytime'] }
                data-dots= { data['showdots'] }  data-web= { data['webCount'] }  data-tab= { data['tabCount'] } 
                data-mob= { data['mobCount'] }>
            
              {list.map((p, index) => (
                <div key={index} className="item">
                  <Blogcard item={p} />
                </div>
              ))}
          </div>

          {/* Navigation Buttons */}
          {data.shownavs === "Yes" && (
            <div className="width100 navBtnMain flex alignCenter justifyBetween absolute">
              <div className="navBtn left flex alignCenter justifyCenter" >
                <i className="fa-solid fa-arrow-left"></i>
              </div>
              <div className="navBtn right flex alignCenter justifyCenter">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          )}

          {/* View All Button */}
          <div className="buyBtnMain textCenter">
            {data.popProBtnText && (
              <a href="/blogs" title="View All">
                <button className="buyBtn floatCenter">{data.popProBtnText}</button>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
