import React from 'react'
import Global from '../Global/Global';

export default function Awardssection({data, list, id}) {
    const {urls} = Global();
    
  return (
    <div className="width100 certificateMain">
      {Array.from({ length: 20 }, (_, i) => i + 1).map((i) => {
        const certificateImgKey = `certificateImg${i}old`;
        const certificateAltKey = `certificateAlt${i}`;
        const certificateNameKey = `certificateName${i}`;
        const quoteLinesKey = `quoteLines${i}`;
        const quoteNameKey = `quoteName${i}`;

        if (data[certificateImgKey]) {
          return (
            <div key={i} className="twoSideSec">
              <div className="width90 maxWidth1300 floatCenter certificateInner">
                <div className="grid gap30 certificateGrid alignCenter">
                  <div className="certificateImgSec">
                    <div className="certificateImg">
                      <img
                        className="width100"
                        src={urls.assets + (data[certificateImgKey])}
                        alt={data[certificateAltKey]}
                      />
                    </div>
                  </div>
                  <div className="certificateTextSec">
                    <div className="certificateText">
                      <p className="certificateName">{data[certificateNameKey]}</p>
                      <div className="borderLeft">
                        <p className="quotesPara">{data[quoteLinesKey]}</p>
                        <p className="quoteSubPara">{data[quoteNameKey]}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  )
}
