import React, { useEffect, useState } from 'react'
import Global from '../Global/Global'
import Loadingstatic from '../Global/Loadingstatic';
import Mainloading from '../Global/Mainloading';

export default function Footer() {

    const {callApi, urls, empty} = Global();
    const [data, setFooterData] = useState(null)
    const [social, setSocial] = useState(null)
    const [footerClass, setFooterClass] = useState("")
    const [loading, setLoading] = useState(true)
    
    const [formData, setFormData] = useState({
        email: '',
        required: 'email',
        tag: 'Newsletter',
        pageurl: window.location.href,
    });

    
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resp = await callApi(formData, "formSubmitReact", "POST", true)
        if(resp.status !== undefined && resp.status === "OK"){
            window.callAlert(resp.message, "successAlert")
            window.location.href="/newsletter/"+resp.enqid
        }
        
    };

    const getFooterData = async () => {
        const resp = await callApi([], "getFooterData", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            setFooterData(resp.sections.sections[0].data)
            setFooterClass(resp.sections.sections[0].class)
            setSocial(resp.social)
        }
        setLoading(false)
    }

    useEffect(() => {
        getFooterData()
    }, [])
  return (
    <>
        {
            loading &&
            <Loadingstatic />
        }
        {
            !loading && data !== null &&
            <div className={footerClass}>
                <div className="width100 footerMain" style={{backgroundImage: "url('" + urls.assets + data.bgImgold + "'); background-position: center; background-repeat: no-repeat; background-size: cover"}}>
                    <div className="width90 maxWidth1100 floatCenter">
                        <div className="subsSec">
                            <div className="grid subsCusGrid gap50 alignCenter">
                                <div className="subsLeftSec">
                                    <p className="subsHead">{data.subsHeading}</p>
                                    <p className="subsPara">{data.subsPara}</p>
                                </div>
                                <div className="subsRightSec">
                                    <form onSubmit={handleSubmit}>
                                        <div className="grid subsInputGrid">
                                            <div className="subsInput">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    placeholder="e.g info@example.com"
                                                    required
                                                />
                                            </div>
                                            <div className="subsBtnSec">
                                                <button className="subsBtn" type='submit'>{data.subsBtnText} <i className="fa-regular fa-paper-plane"></i></button>
                                            </div>
                                        </div>
                                    </form>    
                                </div>
                            </div>
                        </div>


                        <div className="footerLinkMainSec">
                            <div className="grid footerLinkGrid gap30">
                                <div className="footerAbout">
                                    <div className="footerLogo">
                                        <a href={ data.footerLogoLink } title={ data.footerLogoAlt }>
                                            <img src={ urls.assets + data.footerLogoold} alt= { data.footerLogoAlt } />
                                        </a>
                                    </div>
                                    <p className="footerAbout">{ data.footerAbout }</p>
                                    <div className="joinBtnSec">
                                        <a href={ data.footerBtnLink } title={ data.footerBtnText }>
                                            <button className="joinBtn">{ data.footerBtnText }</button>
                                        </a>
                                    </div>
                                </div>

                                <div className="footerLinks">
                                    <p className="footerHead">{ data.impLinksHeading1 }</p>
                                    <div className="footerExLinkSec">
                                        <ul className="footerUlLinks">
                                            {
                                                [...Array(7)].map(function(item, index){
                                                    var i = index + 1
                                                    if (!empty(data['title'+i])) {
                                                        return(
                                                            <li className="footerLiLinks" key={"key" + index}>
                                                                <a href={data['titleLink' + i]} title={ data['title' + i] }>{ data['title' + i]}</a>
                                                            </li>
                                                        )
                                                    }
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="footerLinks">
                                    <p className="footerHead">{ data.impLinksHeading2 }</p>
                                    <div className="footerExLinkSec">
                                        <ul className="footerUlLinks">
                                            {
                                                [...Array(7)].map(function(item, index){
                                                    var i = index + 1
                                                    if (!empty(data['title2'+i])) {
                                                        return(
                                                            <li className="footerLiLinks" key={"key" + index}>
                                                                <a href={data['titleLink2' + i]} title={ data['title2' + i] }>{ data['title2' + i]}</a>
                                                            </li>
                                                        )
                                                    }
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className="footerInfoSec">
                                    <p className="footerHead">Contact Info</p>
                                    {
                                        (!empty(data['addressHead'])) &&
                                        <>
                                            <p className="footerSubHead">{data['addressHead']}</p>
                                            <div className="footerInfoCard footerBorder">
                                                <div className="grid footerInfoGrid gap20 alignCenter">
                                                    <div className="footerInfoIcon flex alignCenter justifyCenter">
                                                        <i className="fa-solid fa-location-dot"></i>
                                                    </div>
                                                    <div className="footerInfoTextSec">
                                                        {
                                                            [...Array(3)].map(function(item, index){
                                                                var i = index + 1
                                                                if (!empty(data['address'+i])) {
                                                                    return(
                                                                        <p className="footerInfoText" key={"add"+i}>
                                                                            <a href={ data['addressLink' + i] }
                                                                                title={ data['address' + i] }>{ data['address' + i] }</a>
                                                                        </p>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        (!empty(data['contactHead'])) &&
                                        <>
                                        <p className="footerSubHead">{data['contactHead']}</p>
                                        <div className="footerInfoCard footerBorder">
                                            <div className="grid footerInfoGrid gap20 alignCenter">
                                                <div className="footerInfoIcon flex alignCenter justifyCenter">
                                                    <i className="fa-solid fa-phone"></i>
                                                </div>
                                                <div className="footerInfoTextSec">
                                                    {
                                                        [...Array(6)].map(function(item, index){
                                                            var i = index + 1
                                                            if (!empty(data['conatctNo'+i])) {
                                                                return(
                                                                    <p className="footerInfoText" key={"con"+i}>
                                                                        <a href={ "tel:" + data['conatctNo' + i] }
                                                                            title={ data['conatctNo' + i] }>{ data['conatctNo' + i] }</a>
                                                                    </p>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                    {
                                        (!empty(data['emailHead'])) &&
                                        <>
                                    <p className="footerSubHead">{data['emailHead']}</p>
                                    <div className="footerInfoCard">
                                        <div className="grid footerInfoGrid gap20 alignCenter">
                                            <div className="footerInfoIcon flex alignCenter justifyCenter">
                                            <i className="fa-solid fa-envelope"></i>
                                            </div>
                                            <div className="footerInfoTextSec">
                                                {
                                                    [...Array(6)].map(function(item, index){
                                                        var i = index + 1
                                                        if (!empty(data['email'+i])) {
                                                            return(
                                                                <p className="footerInfoText" key={"email"+i}>
                                                                    <a href={ "mailto:" + data['email' + i] }
                                                                        title={ data['email' + i] }>{ data['email' + i] }</a>
                                                                </p>
                                                            )
                                                        }
                                                    })
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>

                                    </>
                                        }
                                </div>
                            </div>
                        </div>

                        <div className="copyRightMainSec">
                            <div className="flex alignCenter justifyBetween gap20">
                                <div className="socialLinksMain flex alignCenter justifyCenter gap20">
                                    <div className="socialTextMain">
                                        <p className="socialText">Follow Social:</p>
                                    </div>

                                    {
                                        
                                        social.map(function(item, index){
                                            var i = index + 1
                                            return(
                                                <div className="socialIconMain" key={"social" + index}>
                                                    <a href={ item['link'] } title={ item['type'] }>
                                                        <div className="socialLinks flex alignCenter justifyCenter">
                                                            <i className={"fa-brands " + item['icon'] }></i>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="copyInnerSec">
                                    <p className="footerCopyText">© 2024 All Rights Reserved</p>
                                    <p className="footerCopyText">Designed with <span style={{color: "red", fontSize: "20px"}}>&hearts;</span> by <a href="https://www.website99.net/"> Website99</a> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        }
        <Mainloading />
    </>
  )
}
