import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import { useParams } from "react-router-dom";
import Menu from '../Menu/Menu';
import Global from '../Global/Global';
import Loadingstatic from '../Global/Loadingstatic';
import Blogcard from '../Sections/Blogcard';

export default function Blogs() {
    const { slug } = useParams();
    const {callApi, addCss, addScript, urls, empty} = Global();
    const [loading, setLoading] = useState(true)
    
    const [blogs, setBlogs] = useState([])
    const [pageData, setPageData] = useState("")
    const [lastpage, setLastPage] = useState(1)
    const [cpage, setCPage] = useState(1)

    const getBlogsData = async () => {
      const resp = await callApi({"pageid": 'All_Blogs_Page'}, "getPageReact", "POST")
      if(resp.status !== undefined && resp.status === "OK"){
          setPageData(resp.getSections)
          getBlogs(1)
      }
  }

    const getBlogs = async (cpage) => {

        const resp = await callApi({}, "getBlogsReact?page="+cpage, "POST")
        if(resp.status !== undefined && resp.status === "OK"){
          setBlogs(resp.blogs)
          setLastPage(resp.lastpage)
          setLoading(false)
        }
        
    }

    const changePage = (page) => {
        setCPage(page)
        getBlogs(page)
    }

    useEffect(() => {
        getBlogsData()
        
        const less = addCss( urls.assets + "assets/common/All_Blogs_Page.css");
        const script = addScript( urls.assets + 'assets/common/All_Blogs_Page.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };
    }, [])
  return (
    <>
        <Menu />
        {
            loading &&
            <div style={{padding:"100px 0"}} >
                <Loadingstatic />
            </div>
        }
        {
          !loading && !empty(pageData) &&
          
          <div
              className="shortBanMain"
              style={{ backgroundImage: `url(${ urls.assets + pageData.sections[0].data.bgImgold})` }}
          >
              <div className="width90 maxWidth600 floatCenter textCenter">
                  <p className="heading">{pageData.sections[0].data.heading}</p>
                  <p className="linksPara">{pageData.sections[0].data.sub_para}</p>
              </div>
          </div>
        }
        {
            !loading &&
            blogs.length > 0 &&
          <>
            
            <div className="blogsV2">
                <br />
                <div className="maxWidth1500 float-center grid grid4 gap20">
                    {
                      blogs.map((item, index) => {
                        return( 
                            <Blogcard key={index} item={item} />
                        )
                      })
                    }
                </div>
                <br /><br />
            </div>
            </>
        }
        {
            !loading &&
            blogs.length === 0 &&
          <>
          <div className="blogsV2 center-text">
              <br />
                <p className="allHeading">No Blogs Found</p>
              <br />
            </div>
          </>
        }
        {
            !loading &&
          <>
          <div className="blogsV2">
          <div className="maxWidth1500 float-center center-text navigationArea">
            {
              [...Array(lastpage)].map((item, index) => {
                  return(
                    <span href={"?page=" + (index + 1)} className={"navigations " + ((index + 1) == cpage ? 'activeNav' : '') } onClick={() => changePage((index + 1))}>
                        {index + 1}
                    </span>
                  )
              })
            }
              {/* {renderPagination()} */}
          </div>
          </div>
          </>
        }
        
        <Footer />
    </>
  )
}
