import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';

export default function Sidebar({ active, userName }) {
    const {callApi, empty, urls, getUser} = Global();
    const handleLogout = () => {
        localStorage.removeItem('userid')
        localStorage.removeItem('useremail')
        window.callAlert("Logged out!", "successAlert")
        window.location.href="/login"
    };

  return (
    <>
        <div className="userSidebar">
            <div className={`sidebarBlocks ${active === "Dashboard" ? "active" : ""}`}>
                <a href="/user/dashboard" title="My Dashboard">
                <div className="sideBarProfile">
                    <div className="sideBarProfileImage">
                    <i data-feather="sliders"></i>
                    </div>
                    <div className="sidebarProfile">
                    <p><strong>Dashboard</strong></p>
                    </div>
                </div>
                </a>
            </div>

            <div className={`sidebarBlocks ${active === "Profile" ? "active" : ""}`}>
                <a href="/user/profile" title="My Profile">
                <div className="sideBarProfile">
                    <div className="sideBarProfileImage">
                    <i data-feather="user"></i>
                    </div>
                    <div className="sidebarProfile">
                    <small>Hello</small>
                    <p><strong>{userName}</strong></p>
                    </div>
                </div>
                </a>
            </div>
            
            <div className={`sidebarBlocks ${active === "Donations" ? "active" : ""}`}>
                <a href="/user/donations" title="My Donations">
                <div className="sideBarProfile">
                    <div className="sideBarProfileImage">
                    <i data-feather="heart"></i>
                    </div>
                    <div className="sidebarProfile">
                    <p><strong>My Donations</strong></p>
                    </div>
                </div>
                </a>
            </div>

            <div className="sidebarBlocks">
                <a
                href="#"
                title="Logout"
                className="logoutClass"
                onClick={handleLogout}
                >
                <div className="sideBarProfile">
                    <div className="sideBarProfileImage">
                    <i data-feather="log-out"></i>
                    </div>
                    <div className="sidebarProfile">
                    <p><strong>Logout</strong></p>
                    </div>
                </div>
                </a>
            </div>
            </div>
    </>
  )
}
