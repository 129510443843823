import React, { useState } from 'react'
import Global from '../Global/Global';

export default function Videogallery({data, list, id}) {
    const {urls} = Global();

  return (
    <>
    <div className="viMain">
      {/* Heading Section */}
      <div className="width90 maxWidth700 floatCenter textCenter">
        <span className="line"></span>
        <p className="abtHead">{data.smallHead}</p>
        <p className="abtSubHead">{data.mainHeading}</p>
        <p className="abtPara">{data.subPara}</p>
      </div>

      {/* Video Grid Section */}
      <div className="width90 maxWidth1500 floatCenter">
        <div className="grid grid3 gap30">
          {Array.from({ length: 30 }, (_, index) => {
            const i = index + 1;
            if (!data[`cardLink${i}`]) return null;

            return (
              <div className="vidCard" key={i}>
                {/* Video Thumbnail */}
                <div
                  className="vidGalSec"
                  data-vidsrc={data[`cardLink${i}`]}
                >
                  <img
                    className="width100"
                    src={ urls.assets + data[`cardThumb${i}old`]}
                    alt={data[`cardHeading${i}`]}
                  />
                </div>

                {/* Video Text and Button */}
                <div className="vidTextSec grid custGrid gap10 alignCenter">
                  <div className="textSec">
                    <p className="vidHead">{data[`cardHeading${i}`]}</p>
                    <p className="vidPara">{data[`cardPara${i}`]}</p>
                  </div>
                  <div
                    className="playerBtn flex alignCenter justifyCenter"
                    data-vidsrc={data[`cardLink${i}`]}
                  >
                    <i className="fa-solid fa-play"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>


        <div className="popUpOuter">
          <div className="popUpVidSec">
            <div className="closeBtnSec flex alignCenter justifyCenter" >
              <i className="fa-solid fa-xmark"></i>
            </div>
            <div className="popUpVideo flex alignCenter justifyCenter">
              
            </div>
          </div>
        </div>
    </div>
    </>
  )
}
