import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import { useParams } from "react-router-dom";
import Menu from '../Menu/Menu';
import Global from '../Global/Global';
import {InlineShareButtons} from 'sharethis-reactjs';
import Loadingstatic from '../Global/Loadingstatic';

export default function Campaign(props) {
    const { slug } = useParams();
    const {callApi, addCss, addScript, urls, empty} = Global();
    const [loading, setLoading] = useState(false)
    
    const [product, setProduct] = useState(null)
    const [relatedList, setRelatedList] = useState([])
    const [logo, setLogo] = useState("")

    const getProduct = async () => {
        const resp = await callApi({"slug": slug}, "getProductReact", "POST")
        
        if(resp.status !== undefined && resp.status === "OK"){
            if(empty(resp.redirect)){
                setProduct(resp.product)
                getRelatedProducts(resp.product.id, resp.product.c_id, resp.product.ctype)
                setLogo(resp.logo)
            }
            
        }
        setLoading(false)
    }

    const getRelatedProducts = async (id, cid, type) => {
        const resp = await callApi({"id": id, cid: cid, type: type}, "getRelatedProductsReact", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            setRelatedList(resp.products)
        }
    
    }

    useEffect(() => {
        getProduct()
        
        const less = addCss( urls.assets + "assets/common/Product_Page.css");
        const script = addScript( urls.assets + 'assets/common/Product_Page.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };
    }, [])
  return (
    <>
        <Menu />
        {
            loading &&
            <div style={{padding:"100px 0"}} >
                <Loadingstatic />
            </div>
        }
        {
            !loading &&
            product !== null &&
                <div className="pdpMain">
                    <div className="width90 maxWidth1300 floatCenter">
                        <div className="grid pdpGrid gap30">
                        <div className="pdpLeft">
                            {
                                product.images.length > 0 &&
                                product.images.map((item, index) => {
                                    if(index > 0){
                                        return(
                                            <img className="width100" src={ urls.assets + item.image} alt={product.pname} key={"image" + index} />
                                        )
                                    }
                                })
                            }
                            
                            <div className="pdpText">
                            <div className="tagSec flex alignCenter gap20">
                                <div className="tagCardMain">
                                <div className="grid gap10 innerGrid alignCenter">
                                    <div className="iconSec">
                                    <i className="fa-solid fa-calendar-days"></i>
                                    </div>
                                    <p className="tagText">{product.date}</p>
                                </div>
                                </div>
                                <div className="tagCardMain">
                                <div className="grid gap10 innerGrid alignCenter">
                                    <div className="iconSec">
                                    <i className="fa-solid fa-tags"></i>
                                    </div>
                                    <p className="tagText">{product.formdata.tags}</p>
                                </div>
                                </div>
                            </div>

                            <p className="mainHeading">{product.pname}</p>
                            
                            <div className='subPara ck-content' dangerouslySetInnerHTML={{__html: product.descriptions[0]['p_data']}} ></div>

                            {
                                !empty(product.formdata.quote_1) &&
                                <div className="quoteSec">
                                    <div className="quoteIcon flex alignCenter justifyCenter">
                                    <i className="fa-solid fa-quote-left"></i>
                                    </div>
                                    <p className="quoteText">{product.formdata.quote_1}</p>
                                    <p className="quoteName">{product.formdata.quote_1_by}</p>
                                </div>
                            }
                            
                            {
                                !empty(product.formdata.quote_2) &&
                                <div className="quoteSec">
                                    <div className="quoteIcon flex alignCenter justifyCenter">
                                    <i className="fa-solid fa-quote-left"></i>
                                    </div>
                                    <p className="quoteText">{product.formdata.quote_2}</p>
                                    <p className="quoteName">{product.formdata.quote_2_by}</p>
                                </div>
                            }
                            
                            <div className='subPara ck-content' dangerouslySetInnerHTML={{__html: product.descriptions[1]['p_data']}} ></div>

                            <div className="tagBotSec">
                                <div className="tagBotInner flex alignCenter gap20">
                                <p className="shareText">Share:</p>
                                <div className="socialIconSec flex alignCenter gap10">
                                    <InlineShareButtons
                                        config={{
                                            alignment: 'center',  // alignment of buttons (left, center, right)
                                            color: 'social',      // set the color of buttons (social, white)
                                            enabled: true,        // show/hide buttons (true, false)
                                            font_size: 16,        // font size for the buttons
                                            labels: 'cta',        // button labels (cta, counts, null)
                                            language: 'en',       // which language to use (see LANGUAGES)
                                            networks: [           // which networks to include (see SHARING NETWORKS)
                                            'whatsapp',
                                            'linkedin',
                                            'messenger',
                                            'facebook',
                                            'twitter'
                                            ],
                                            padding: 12,          // padding within buttons (INTEGER)
                                            radius: 4,            // the corner radius on each button (INTEGER)
                                            show_total: false,
                                            size: 40,             // the size of each button (INTEGER)
                                
                                        }}
                                        />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="relative">
                            
                            <div className="pdpRight">
                            {/* Card Main Section */}
                            <div className="cardMain">
                                <div className="cardImgSec relative">
                                <img
                                    className="width100"
                                    src={urls.assets + product.defaultImage.image}
                                    alt="Event"
                                />
                                {
                                    !empty(product.goal) &&
                                    <p className="percent">{product.raisedper}%</p>
                                }
                                </div>
                                <div className="cardTextSec">
                                    
                                {
                                    !empty(product.goal) &&
                                        <div className="progressBar">
                                            <div className="outerDiv">
                                            <div className="innerDiv" style={{width: product.raisedper + '%'}}></div>
                                            </div>
                                        </div>
                                }
                                {
                                    !empty(product.goal) &&
                                    <div className="cardInfoInner">
                                        <div className="grid grid2 gap10">
                                        <div className="innerSec">
                                            <p className="greyPara">Raised</p>
                                            <p className="donatePara">₹{product.raised}</p>
                                        </div>
                                        <div className="innerSec">
                                            <p className="greyPara">Goal</p>
                                            <p className="donatePara">₹{product.goal}</p>
                                        </div>
                                        </div>
                                    </div>
                                }
                                <p className="cardHead">{product.pname}</p>
                                <p className="cardPara">
                                    {product.formdata.card_description}
                                </p>

                                <div className="cardInfoInner padding10">
                                    <div className="grid grid2 gap10 textCenter">
                                    <div className="innerSec">
                                        <p className="greyPara">Start Date</p>
                                        <p className="donatePara">{product.start_date_string}</p>
                                    </div>
                                    <div className="innerSec">
                                        <p className="greyPara">End Date</p>
                                        <p className="donatePara">{ empty(product.end_date_string) ? '-' : product.end_date_string}</p>
                                    </div>
                                    </div>
                                </div>
                                {
                                    product.ctype === "Ongoing" && (product.raisedper < 100) &&
                                    <div className="joinBtnSec">
                                        <a href={ (product.c_id === 2) ? '/sponser/'+product.slug : '/donate/'+product.slug} title="Donate Now">
                                            <button className="joinBtn">Donate Now</button>
                                        </a>
                                    </div>
                                }
                                
                                {
                                    product.raisedper >= 100 &&
                                    <div className='ck-content'>
                                        <p style={{color:"red", marginTop:"10px", textAlign:"center", fontSize:"20px"}}>
                                            <strong>
                                                DONATION LIMIT REACHED
                                            </strong>
                                        </p>
                                    </div>
                                }
                                </div>
                            </div>

                            {
                                !empty(product.formdata.helplineno) &&
                                <div className="orgMain">
                                    {/* <p style={{fontSize:"20px", paddingBottom:"10px"}} className='center-text' >HELPLINE NO</p> */}
                                    {
                                        !empty(logo) &&
                                        <div className="logoSec textCenter">
                                            <img
                                                className="width80"
                                                src={urls.assets + logo}
                                                alt="Pari Foundation"
                                            />
                                        </div>
                                    }
                                    
                                    {
                                        product.formdata.helplineno.split(",").map((item, index) => {
                                        
                                                return(
                                                    <p className="noSec" key={"help"+index} style={{paddingTop:"10px"}}>
                                                        <a href={ 'tel:' + item} className="donateBtnSec flex alignCenter">
                                                            <span>
                                                            <i className="fa-solid fa-phone"></i>
                                                            </span>
                                                            &nbsp;&nbsp;{item}
                                                        </a>
                                                    </p>
                                                )
                                            })
                                        }
                                    
                                    
                                </div>
                            }

                            {/* Blog Section */}
                            {
                                relatedList.length > 0 &&
                                <div className="blogSec">
                                    <p className="blogsMainHead textCenter">Related Blogs</p>
                                    {
                                    relatedList.map((blog, index) => (
                                    <div key={index} className="blogCard">
                                        <a href={'/'+blog.url} title={urls.name}>
                                        <div className="grid blogGrid gap10">
                                            <div className="blogImgSec">
                                            <img className="width100" src={ urls.assets + blog.image} alt={blog.name} />
                                            </div>
                                            <div className="blogRightText">
                                            <p className="blogHead line1">{blog.name}</p>
                                            <p className="blogPara line3">{blog.card_description}</p>
                                            <button>
                                                Read More <i className="fa-solid fa-angle-right"></i>
                                            </button>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                    ))}
                                </div>
                            }
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            }
        <Footer />
    </>
  )
}
